import React, { useEffect, useState } from "react";
import { NavLink, useMatch, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { colors } from "../../Shared/colors";
import { store } from "../../app/store";
import { apiSlice } from "../../features/api/apiSlice";
import { logo } from "../../Shared/config";
import { useLogoutMutation } from "../../features/employeeSlice";
import { useChangePasswordMutation } from "../../features/authSlice";
import { Toaster, toast } from "react-hot-toast";
import {
  addEmp,
  notificationCount,
  postgetNotify,
} from "../../services/socket";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { decryptData } from "../../services/crypto";
import { useGetAllNotificationsQuery } from "../../features/notificationSlice";
import ProfileCard from "../profileCard/ProfileCard"

export const Header = ({ staff }) => {
  let login = useMatch("/login");
  let notFound = useMatch("/notfound");
  let changePassword = useMatch(`/changePassword/:token`);
  let unAuthorized = useMatch("/unauthorized");
  let match = false;
  const token = localStorage.getItem('token');
  if (login || notFound || unAuthorized || changePassword) {
    match = true;
  }

  const [Notifications, setNotifications] = useState();

  let empId = null;
  let storedStaffId = localStorage.getItem("id");

  if (storedStaffId) {
    try {
      empId = decryptData(storedStaffId);
    } catch (error) {
      console.error("Failed to decrypt data:", error);
    }
  }
  let fullName = null;
  let storedStaffName = localStorage.getItem("staffName");

  if (storedStaffName) {
    try {
      fullName = decryptData(storedStaffName);
    } catch (error) {
      console.error("Failed to decrypt data:", error);
    }
  }

  useEffect(() => {
    try {
      if (empId && fullName) {
        addEmp(empId, fullName);
      }
      if (empId) {
        postgetNotify(empId);
      }
    } catch (error) {
      console.error("Error in addEmp or postgetNotify:", error);
    }
  }, [empId, fullName]);

  useEffect(() => {
    try {
      notificationCount((data) => {
        setNotifications(data);
      });
    } catch (error) {
      console.error("Error in notificationCount:", error);
    }
  }, []);

  const [changePass] = useChangePasswordMutation();

  const { data: notificationsData, isLoading, error } = useGetAllNotificationsQuery(undefined, { skip: !token });
  const isRead = notificationsData?.some(notification => !notification.isRead);

  let user = null;
  const storedData = localStorage.getItem("user");

  if (storedData) {
    try {
      user = decryptData(storedData);
    } catch (error) {
      console.error("Failed to decrypt data:", error);
    }
  }


  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.clear();
    sessionStorage.clear();
    store.dispatch(apiSlice.util.resetApiState());
    setdrop(false);
    navigate("/login");
  };

  const handleReset = (e) => {
    e.preventDefault();

    let staffEmail = null;
    let storedStaffEmail = localStorage.getItem("email");

    if (storedStaffName) {
      try {
        staffEmail = decryptData(storedStaffEmail);
      } catch (error) {
        console.error("Failed to decrypt data:", error);
      }
    }

    let data = {
      email: staffEmail,
    };
    changePass(data)
      .unwrap()
      .then((res) => {
        toast.success("Please check your email inbox");
        setTimeout(() => {
          window.open("https://mail.google.com/mail/u/0/#inbox", "_blank");
        }, 1500);
      })
      .catch((err) => {
        toast.error("error");
      });
  };
  const [logout] = useLogoutMutation();
  const handleAPILogout = async () => {
    try {
      await logout().unwrap();
      handleLogout();
    } catch (error) {
      console.error("Logout error:", error);
    }
  };

  useEffect(() => {
    if (isRead) {
      console.log("Unread notifications detected!");
    }
  }, [isRead]);

  const [drop, setdrop] = useState(false);
  return (
    <Main match={match}>
      <Logo>
        <img src={logo} alt="logo" />
      </Logo>
      <Container>
        <NavLink to="/">DashBoard</NavLink>
        <NavLink to="leads">Leads</NavLink>
        {/* {(user?.role === "ceo" || user?.role === "admin" || user?.role === "sm") && ( <NavLink to="social">SMM</NavLink>)} */}
        <NavLink to="clients">Clients</NavLink>
        {(user?.role === "ceo" || user?.role === "admin") && (<NavLink to="staff">Staff</NavLink>)}
        <NavLink to="affiliate">Affiliate</NavLink>
        <NavLink to="inventory">Inventory</NavLink>
        <NavLink to="accounts">Accounts</NavLink>
        <NavLink to="targets">Targets</NavLink>
        <NavLink to="reports">Reports</NavLink>
        <NavLink to="training">Training</NavLink>
        <NavLink to="policies">Policies</NavLink>
        <NavLink to="documentation">Documentation</NavLink>
      </Container>
      <ProfileSection>
        <NotificationTab to="/leads/notifications">
          <NotificationsActiveIcon />
          {isRead && (
            <span
              style={{
                display: 'inline-block',
                position: 'absolute',
                width: '10px',
                height: '10px',
                backgroundColor: `${colors.brown}`,
                borderRadius: '50%',
                top: '0%',
                right: '-10%',
                animation: 'blink 1s infinite',
              }}
            ></span>
          )}
        </NotificationTab>
        {!token ? (
          <p>Please log in to view data.</p>
        ) : (
          <ProfileCard
            user={user}
            staff={staff}
            handleLogout={handleAPILogout}
            handleReset={handleReset}
          />
        )}
      </ProfileSection>

      <Toaster />
    </Main >
  );
};

const Main = styled.div`
  position: fixed;
  background-color: black;
  height: 10vh;
  width: 100%;
  display: ${(props) => (props.match ? "none" : "flex")};
  justify-content: space-between;
  align-items: center;
  background-color: ${colors.white};
  z-index: 10;
`;
const Logo = styled.div`
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 30%;
    height: 100%;
    object-fit: contain;
  }
`;

const Container = styled.div`
  width: 70%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  a {
    height: 100%;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 2px solid white;
    color: ${colors.textLight};
    transition: all 0.3s ease-out;
    font-size: clamp(1em, 1vh, 2em);
    :hover {
      color: ${colors.brown};
      border-bottom: 2px solid ${colors.brown};
    }
  }
  .active {
    color: ${colors.brown};
    border-bottom: 2px solid ${colors.brown};
  }
`;
const NotificationTab = styled(NavLink)`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-left: 20px;
  cursor: pointer;
  color: black;
`;
const ProfileSection = styled.div`
  width: 10%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;