import CryptoJS from "crypto-js";

const secretKey = process.env.REACT_APP_CRYPTO_SECRET_KEY || "243sdgfh456fhgyhvtu387bbjyut$#89hjdb@"; 

export function encryptData(data) {
  try {
    const stringData = typeof data === "string" ? data : JSON.stringify(data);
    return CryptoJS.AES.encrypt(stringData, secretKey).toString();
  } catch (error) {
    // console.error("Failed to encrypt data:", error);
    return null;
  }
}

export function decryptData(encryptedData) {
  try {
    const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
    const decryptedString = bytes.toString(CryptoJS.enc.Utf8);
     try {
        return JSON.parse(decryptedString);
      } catch {
        // console.warn("Decrypted data is not JSON, returning as plain string.");
        return decryptedString;
      }

  } catch (error) {
    // console.error("Failed to decrypt data:", error);
    return null;
  }
}