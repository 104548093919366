import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const BackUrl =
  process.env.REACT_APP_APPLICATION_MOOD === "production"
    ? process.env.REACT_APP_BACKEND_URL_PRODUCTION
    : process.env.REACT_APP_BACKEND_LOCAL_URL;
    console.log(BackUrl)
export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: BackUrl,

    prepareHeaders: (headers) => {
      const token = `Token ${localStorage.getItem("token")}`;
      if (token) {
        headers.set("authorization", `${token}`);
      }
      return headers;
    },
  }),
  tagTypes: [
    "Inventory",
    "Region",
    "Project",
    "Affiliate",
    "Policy",
    "Client",
    "Employee",
    "Leads",
    "Task",
    "Account",
    "Target",
    "Notification",
    "Profile",
  ],
  endpoints: (builder) => ({}),
});
