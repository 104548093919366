import React from 'react';
import styled from 'styled-components';



const Footer = () => {
    return (
        <FooterContainer>
            Powered By
            <img
                src="/assets/icons/V3logo.png"
                alt="v3logo"
                style={{
                    width: '20px',
                    height: '20px',
                    marginLeft: '2px'
                }} />
        </FooterContainer>
    );
};

export default Footer;

const FooterContainer = styled.div`
  display: flex;
  opacity: 0.7;
  justify-content: center;
  align-items: center;
  height: 40px;
 color: '#4a4a4a',
  font-size: 14px;
 
`;