import React, { useEffect, useState } from 'react'
import { Outlet, useLocation, Navigate } from 'react-router-dom'
import { decryptData } from '../services/crypto';
import { useGetProfileCardDataQuery } from '../features/profileSlice';
import { Loader } from '../Shared/Loader';

export const RequireAuth = ({ allowedRoles }) => {

  const [isAuthenticated, setIsAuthenticated] = useState(true);
  let staffId = null;
  let employeeId = localStorage.getItem("id");
  const location = useLocation();

  if (employeeId) {
    try {
      staffId = decryptData(employeeId);
    } catch (error) {
      console.error("Failed to decrypt data:", error);
    }
  }

  const { data: res, isLoading, error, refetch } = useGetProfileCardDataQuery({ employeeId: staffId });

  let role = null;
  let storedStaffRole = localStorage.getItem("role");

  if (storedStaffRole) {
    try {
      role = decryptData(storedStaffRole);
    } catch (error) {
      console.error("Failed to decrypt data:", error);
    }
  }

  useEffect(() => {
    if (staffId) {
      refetch();
    }
  }, [location, staffId, refetch]);

  useEffect(() => {
    if (error?.data?.error?.statusCode === 401) {
      localStorage.removeItem("id");
      localStorage.removeItem("role");
      localStorage.clear();
      setIsAuthenticated(false);
    }
  }, [error]);

  if (isLoading) {
    return <div><Loader/></div>;
  }

  if (!isAuthenticated) {
    return <Navigate to='/login' state={{ from: location }} replace />;
  }

  return (
    allowedRoles?.includes(role)
      ? <Outlet />
      : role
        ? <Navigate to='/unauthorized' state={{ from: location }} replace />
        : <Navigate to='/login' state={{ from: location }} replace />
  );
};
