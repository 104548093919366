import React, { useState, useEffect, useMemo } from "react";
import styled from "styled-components";
import { colors } from "../../../Shared/colors";
import { DataGrid } from "@mui/x-data-grid";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import DeviceThermostatIcon from "@mui/icons-material/DeviceThermostat";
import StarIcon from "@mui/icons-material/Star";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import ShareIcon from "@mui/icons-material/Share";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import { useAssignLeadsMutation, useGetAllLeadsQuery, useProjectassignMutation, useShareLeadMutation, useSingleassignLeadMutation } from "../../../features/leadsSlice";
import {
  useAddFavouriteMutation,
  useGetSingleHierarchyQuery,
  useGetStaffQuery,
} from "../../../features/employeeSlice";
import { Toaster, toast } from "react-hot-toast";
import { Loader } from "../../../Shared/Loader";
import { Puff } from "react-loader-spinner";
import {
  useGetAllProjectsQuery,
  useGetSingleProjectQuery,
} from "../../../features/projectSlice";
import { useLazyGetInventoryByProjectIdQuery } from "../../../features/InventorySlice";
import { useAddNewTaskLeadIdMutation } from "../../../features/taskSlice";
import { TaskTable } from "./TaskTable";
import Tooltip from "@mui/material/Tooltip";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Banks } from "../../../Shared/Banks";
import { FeedbackTable } from "./FeedbackTable";
import moment from "moment";
import { Checkbox, FormControlLabel } from "@mui/material";
import { assignLeadNotification, unSubLeadAssigned } from "../../../services/socket";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { decryptData } from "../../../services/crypto";

export const SearchLeadsDataTable = ({ leads, page, pageSize, setPageSize, setPage, isFetching, handleCheckboxChange, checked }) => {

  const [popUp, setpopUp] = useState(false);
  const [leadId, setleadId] = useState("");
  const [leadIdSm, setleadIdSm] = useState("");
  const [multileadId, setmultileadId] = useState([]);
  const [subType, setsubType] = useState();
  const [tokenDownPayment, settokenDownPayment] = useState(false);
  const [loading, setloading] = useState(false);
  const [closedLost, setclosedLost] = useState(false);
  const [addTask, { isLoading }] = useAddNewTaskLeadIdMutation();
  const [taskPopUp, setTaskPopUp] = useState(false);
  const [feedbackPopup, setfeedbackPopup] = useState(false)
  const [feedbackData, setfeedbackData] = useState([])
  const [leadSharePopUp, setleadSharePopUp] = useState(false);
  const [singleAssignpopup, setsingleAssignpopup] = useState(false);
  const [leadsSharedPopUp, setleadsSharedPopUp] = useState(false);
  const [leadShareData, setleadShareData] = useState({
    sharingEmployee: { label: "", id: "" },
    percentage: ""
  })
  const [Singleleadassign, setSingleleadassign] = useState({
    assignEmployee: { label: "", id: "" },

  })
  const [leadSharedData, setleadSharedData] = useState({
    assignEmployee: [],
  })
  const [projectassign, setprojectassign] = useState(null);
  const [projectpopup, setprojectpopup] = useState(false);
  const [numberPopUp, setnumberPopUp] = useState(false);
  const [phoneData, setphoneData] = useState([]);
  const [doNothing, setdoNothing] = useState(false);
  const [maxDate, setmaxDate] = useState("");
  const [minDate, setminDate] = useState("");
  let { data: project } = useGetAllProjectsQuery();
  const projectOptions = project?.map((proj) => ({
    label: proj.projectName,
    value: proj._id,
  })) || [];

  useEffect(() => {
    const handleEscape = (event) => {
      if (event.keyCode === 27) {
        setpopUp(false);
        setTaskPopUp(false);
        setnumberPopUp(false);
      }
    };

    document.addEventListener("keydown", handleEscape);

    return () => {
      document.removeEventListener("keydown", handleEscape);
    };
  }, []);

  const today = moment();
  const defaultDate = today.format('YYYY-MM-DDTHH:mm');
  const newMinDate = today.clone().subtract(1, 'days').format('YYYY-MM-DDTHH:mm');

  useEffect(() => {
    setminDate(newMinDate);
  }, [newMinDate]);

  useEffect(() => {
    setmaxDate(defaultDate);
  }, [defaultDate]);
  const [selectedProject, setSelectedProject] = useState("");
  const [task, settask] = useState({
    currentTaskType: "",
    currentSubTypeTask: "",
    completedDate: defaultDate,
    reason: "",
    comment: "",
    nextTaskType: "",
    deadline: "",
    DProjectID: "",
    DTprojectBlockName: "",
    invenID: "",
    developername: "",
    ammount: 0,
    paymentMode: "",
    bankName: "",
    transactionNo: 0,
    totalAmount: 0,
    leadStatus: "",
    // depositeDate: "",
    status: "",
    attachment: "",
    approvedPayment: "",
    cnicfront: "",
    cnicback: "",
    personalimage: "",
    leadId: "",
    clientId: "",
    clientname: "",
    cnic: "",
    phoneNo: "",
    nationality: "",
    address: "",
  });



  let { data: singleProject } = useGetSingleProjectQuery(task.DProjectID);

  let [shareLead, { isLoading: leadShareLoading }] = useShareLeadMutation();

  let [assignLeads, { isLoading: leadsAssignLoading }] = useAssignLeadsMutation();

  let [singleassignLead, { isLoading: singleleadAssignLoading }] = useSingleassignLeadMutation();

  let [Projectassign, { isLoading: projectassignLoading }] = useProjectassignMutation();


  let staffId = null;
  let storedStaffId = localStorage.getItem("id");

  if (storedStaffId) {
    try {
      staffId = decryptData(storedStaffId);
    } catch (error) {
      console.error("Failed to decrypt data:", error);
    }
  }

  let role = null;
  let storedStaffRole = localStorage.getItem("role");

  if (storedStaffRole) {
    try {
      role = decryptData(storedStaffRole);
    } catch (error) {
      console.error("Failed to decrypt data:", error);
    }
  }

  const { data: hierarchyStaff } = useGetSingleHierarchyQuery({ staffId });
  const { data: staff } = useGetStaffQuery();

  const staffOptions = useMemo(() => {
    let options = [];
    hierarchyStaff?.map((staff) =>
      options.push({
        id: staff?._id,
        label: staff?.firstName + " " + staff?.lastName,
      })
    );

    return options;
  }, [hierarchyStaff]);

  const allStaffOptions = useMemo(() => {
    let options = [];
    staff?.map((staff) =>
      options.push({
        id: staff?._id,
        label: staff?.firstName + " " + staff?.lastName,
      })
    );

    return options;
  }, [staff]);

  const [trigger, { data: inventory }] = useLazyGetInventoryByProjectIdQuery();

  useEffect(() => {
    if (singleProject) {
      setloading(false);
    }
  }, [singleProject]);

  useEffect(() => {
    if (task?.DProjectID) {
      setSelectedProject(task.DProjectID);
    }
  }, [task]);

  useEffect(() => {
    if (task.DProjectID || task.DTprojectBlockName) {
      let project = task.DProjectID;
      let block = task.DTprojectBlockName;
      trigger({ project, block });
    }
  }, [task.DProjectID, task.DTprojectBlockName, trigger]);

  let inventoryData = [];
  inventory?.forEach((inven) => {
    if (inven.status === "Available") {
      inventoryData.push({
        unitNo: inven.unitNo,
        id: inven._id,
      });
    }
  });

  useEffect(() => {
    if (task.totalAmount && task.currentSubTypeTask) {
      let calculatedAmount = 0;

      switch (task.currentSubTypeTask) {
        case 'TP':
          calculatedAmount = task.totalAmount * 0.1;
          break;
        case 'PDP':
          calculatedAmount = task.totalAmount * 0.25;
          break;
        case 'DP':
          calculatedAmount = task.totalAmount * 0.25;
          break;
        default:
          calculatedAmount = 0;
      }

      const roundedAmount = parseFloat(calculatedAmount.toFixed(2));

      settask((prevTask) => ({
        ...prevTask,
        ammount: roundedAmount,
      }));
    }
  }, [task.totalAmount, task.currentSubTypeTask]);

  const handleProject = (e) => {
    let { name, value } = e.target;
    setloading(true);
    settask({ ...task, DTprojectBlockName: "", [name]: value });
  };

  let handleChange = (e, closed) => {
    let { name, value } = e.target;

    if (name === "nextTaskType") {
      setdoNothing(value === "doNothing");
    }

    if (name === "paymentMode" && value !== "bank") {
      settask({ ...task, [name]: value, bankName: "" });
    } else if (closed === "closed") {
      settask({
        ...task, nextTaskType:
          "doNothing", [name]: value
      });

    }
    else {
      settask({
        ...task, nextTaskType:
          task.nextTaskType, [name]: value
      });
    }

  };
  const handleFileChange = (e) => {
    const { name } = e.target;
    settask((prevstate) => ({
      ...prevstate,
      [name]: e.target.files[0],
    }));
    e.target.value = '';
  };

  const handleType = (e) => {
    const { value } = e.target;
    if (value === "Call") {
      setsubType([
        "SMS",
        "Call connected",
        "Call Not Connected",
        "Whatsapp",
        "Followed Up",
      ]);
      setclosedLost(false);
      setdoNothing(false);

    } else if (value === "Meeting") {
      setsubType([
        "Vedio Meeting",
        "Head Office Meeting",
        "Outdoor",
        "Site",
        "Walk In",
      ]);
      setdoNothing(false);

      setclosedLost(false);
    } else if (value === "Sales") {
      let filteredSubType = ["TP", "PDP", "DP", "closed-won"];

      if (task?.leadStatus === "TP") {
        filteredSubType = filteredSubType.filter((type) => type !== "TP");
      } else if (task?.leadStatus === "PDP") {
        filteredSubType = filteredSubType.filter(
          (type) => type !== "TP"
        );
      } else if (task?.leadStatus === "DP") {
        filteredSubType = filteredSubType.filter(
          (type) => type !== "TP" && type !== "PDP" && type !== "DP"
        );
      }
      setsubType(filteredSubType);
      setclosedLost(false);
      setdoNothing(false);

    } else if (value === "closed-lost") {
      settask((prevTask) => ({
        ...prevTask,
        nextTaskType: "doNothing",
      }));
      setsubType(["Nothing"]);
      setdoNothing(true);
      setclosedLost(true);
    }
    handleChange(e, value === "closed-lost" ? "closed" : null);
  };
  const handleSubtype = (e) => {
    settokenDownPayment(false);
    if (
      e.target.value === "TP" ||
      e.target.value === "DP" ||
      e.target.value === "PDP"
    ) {
      settokenDownPayment(true);
    }
    handleChange(e);
  };

  const handleLeadShare = () => {
    let body =
    {
      leadID: leadId,
      sharingEmployee: leadShareData.sharingEmployee.id,
      percentage: leadShareData.percentage
    }

    shareLead(body).unwrap()
      .then((res) => {
        toast.success("lead Shared successfully!")
        setleadSharePopUp(false);
      })
      .catch((err) => {
        toast.error(err?.data?.error?.message || "Error sharing Lead")
      })
      .finally(() => {
        setleadShareData({
          sharingEmployee: { label: "", id: "" },
          percentage: ""
        })
      })
  }


  // useEffect(() => {
  //   setupSocket();
  // }, []);

  const handleSingleLeadassign = () => {

    if (!Singleleadassign.assignEmployee || !Singleleadassign.assignEmployee.id) {
      toast.error("Please select an employee before assigning the lead.");
      return;

    }
    let body =
    {
      leadId: leadId,
      employeeId: Singleleadassign.assignEmployee.id,
    }

    let data = {
      empIds: Singleleadassign.assignEmployee.id,
      leadId: leadIdSm,
    }

    singleassignLead(body).unwrap()
      .then((res) => {
        toast.success("lead Assign successfully!")
        assignLeadNotification(data);
        unSubLeadAssigned();
        setsingleAssignpopup(false);
      })
      .catch((err) => {
        toast.error(err?.data?.error?.message || "Error assign Lead")
      })
      .finally(() => {
        setSingleleadassign({
          assignEmployee: { label: "", id: "" },
        })
      })
  }
  const handleChangeproj = () => {
    if (!projectassign || !projectassign.value) {
      toast.error("Please select a project before changing.");
      return;
    }
    const body = {
      leadId: leadId,
      projectId: projectassign.value,
    };

    Projectassign({ leadId: leadId, projectId: projectassign.value })
      .unwrap()
      .then((res) => {
        toast.success("Project changed successfully!");
        setprojectpopup(false);
        setprojectassign(null);
      })
      .catch((err) => {
        console.error("Error changing project:", err);
        toast.error("Error changing project");
      });
  };

  const handleMultiLeadAssign = () => {
    if (leadSharedData.assignEmployee.length === 0 || multileadId.length === 0) {
      toast.error("Please select at least one lead");
      return;
    }
    const leadAssignmentData = {
      leadIds: multileadId,
      employeeIds: leadSharedData.assignEmployee.map(emp => emp.id),
    };


    let data = {
      empIds: leadSharedData.assignEmployee.map(emp => emp.id),
      leadId: leads?.leads?.filter((lead) => multileadId.includes(lead._id))
        .map((lead) => lead.leadID)
    }

    assignLeads(leadAssignmentData)
      .unwrap()
      .then((res) => {
        toast.success("Leads assigned successfully!");
        assignLeadNotification(data);
        unSubLeadAssigned();
        setleadSharedData({ assignEmployee: [] });
        setmultileadId([]);
        setleadsSharedPopUp(false);
      })
      .catch((err) => {
        toast.error(err?.data?.error?.message || "Error assigning leads");
      });
  };
  const handleSave = () => {
    var formdata = new FormData();

    const completedDate = moment(task.completedDate).utc().format('YYYY-MM-DDTHH:mm:ss');

    formdata.append("currentTaskType", task.currentTaskType);
    formdata.append("currentSubTypeTask", task.currentSubTypeTask);
    formdata.append("completedDate", completedDate);
    formdata.append("reason", task.reason);
    formdata.append("comment", task.comment);
    formdata.append("nextTaskType", task.nextTaskType);
    formdata.append("deadline", task.deadline);
    formdata.append("attachment", task.attachment);
    formdata.append("idCardFrontPicture", task.cnicfront);
    formdata.append("idCardBackPicture", task.cnicback);
    formdata.append("cnic", task.cnic);
    formdata.append("address", task.address);
    formdata.append("personalPicture", task.personalimage);
    formdata.append("country", task.nationality);
    formdata.append("phoneNo", task.phoneNo);
    formdata.append("developerName", task.developername);
    formdata.append("projectID", task.DProjectID);
    formdata.append("DTprojectBlockName", task.DTprojectBlockName);
    formdata.append("unitNumber", task.invenID);
    formdata.append("amount", task.ammount);
    formdata.append("totalAmount", task.totalAmount);
    formdata.append("paymentMode", task.paymentMode);
    formdata.append("paymentType", task.currentSubTypeTask);
    formdata.append("bankName", task.bankName);
    formdata.append("transactionId", task.transactionNo);
    // formdata.append("depositeDate", task.depositeDate);
    formdata.append("status", task.status);
    formdata.append("paymentproof", task.approvedPayment);
    formdata.append("clientID", task.clientId)
    let id = task.leadId;
    addTask({ formdata, id })
      .unwrap()
      .then((res) => {
        toast.success("task created successfully");
        settask({
          currentTaskType: "",
          currentSubTypeTask: "",
          completedDate: defaultDate,
          reason: "",
          comment: "",
          nextTaskType: "",
          deadline: "",
          DProjectID: "",
          DTprojectBlockName: "",
          invenID: "",
          address: "",
          nationality: "",
          cnic: "",
          developername: "",
          ammount: "",
          paymentMode: "",
          bankName: "",
          transactionNo: "",
          // depositeDate: "",
          status: "",
          attachment: "",
          cnicback: "",
          cnicfront: "",
          leadStatus: "",
          approvedPayment: "",
          leadId: task.leadId,
        });
        setpopUp(false);
      })
      .catch((error) => {
        toast.error(error?.data?.error?.message);
      });
  };

  const [favouriteLoading, setfavouriteLoading] = useState(false);

  // const { data: leads, isFetching } = useGetAllLeadsQuery();

  const [addFavourite] = useAddFavouriteMutation();

  const handleAddFavourite = (id) => {
    setfavouriteLoading(true);
    const body = {
      LeadID: id,
    };
    addFavourite(body)
      .unwrap()
      .then((res) => {
        toast.success("Added to favourite");
        setfavouriteLoading(false);
      })
      .catch((error) => {
        toast.error(error.data?.message);
        setfavouriteLoading(false);
      });
  };
  const columns = [
    {
      field: "client",
      headerName: "Client",
      width: 150,
      renderCell: (params) => {
        return (
          <Client>
            <Tooltip title={params.row.client}>
              <div>{params.row.client} </div>
            </Tooltip>
            <div>
              {params.row.favourite ? (
                <StarIcon
                  sx={{
                    color: `${colors.starYellow}`,
                    fontSize: `small`,
                    cursor: `pointer`,
                  }}
                />
              ) : (
                <StarBorderIcon
                  sx={{
                    color: `${colors.starYellow}`,
                    fontSize: `small`,
                    cursor: `pointer`,
                  }}
                  onClick={() => handleAddFavourite(params.row.id)}
                />
              )}
              <span><span>Lead

                {params.row.isNew ? (<span style={{ backgroundColor: '#249393', color: 'white', fontSize: '10px', padding: '0 2px', marginLeft: '5px', borderRadius: '3px' }}>New</span>) : (<span></span>)}
              </span>
              </span>
            </div>
          </Client>
        );
      },
    },
    {
      field: "leadId",
      headerName: "Lead ID",
      width: 150,
      renderCell: (params) => {
        return params.row.classification === "veryHot" ? (
          <Id>
            <div>
              <DeviceThermostatIcon
                sx={{
                  color: `${colors.veryHot}`,
                }}
              />
              {params.row.leadId}
            </div>
          </Id>
        ) : params.row.classification === "hot" ? (
          <Id>
            <div>
              <DeviceThermostatIcon
                sx={{
                  color: `${colors.hot}`,
                }}
              />
              {params.row.leadId}
            </div>
          </Id>
        ) : params.row.classification === "moderate" ? (
          <Id>
            <div>
              <DeviceThermostatIcon
                sx={{
                  color: `${colors.moderate}`,
                }}
              />
              {params.row.leadId}
            </div>
          </Id>
        ) : params.row.classification === "cold" ? (
          <Id>
            <div>
              <DeviceThermostatIcon
                sx={{
                  color: `${colors.cold}`,
                }}
              />
              {params.row.leadId}
            </div>
          </Id>
        ) : (
          <Id>
            <div>
              <DeviceThermostatIcon
                sx={{
                  color: `${colors.veryCold}`,
                }}
              />
              {params.row.leadId}
            </div>
          </Id>
        );
      },
    },
    {
      field: "source",
      headerName: "Source",
      width: 150,
    },
    {
      field: "phone",
      headerName: "Phone",
      width: 150,
      valueGetter: (params) =>
        params.row.phone?.length > 0 ? params.row?.phone[0]?.phoneNo : "",
      renderCell: (params) => {
        return (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {params.row.phone?.length > 1 ? (
              <button
                onClick={() => {
                  setphoneData(params.row.phone);
                  setnumberPopUp(true);
                }}
                style={{
                  width: "100%",
                  backgroundColor: `${colors.brown}`,
                  color: `${colors.white}`,
                  padding: "0.5em 0em",
                  cursor: "pointer",
                  borderRadius: "5px",
                  border: `1px solid ${colors.inputBorderWhite}`,
                }}
              >
                {params.row?.phone[0]?.phoneNo}
              </button>
            ) : (
              <a href={`tel:+${params.row?.phone[0]?.phoneNo}`}>{params.row?.phone[0]?.phoneNo}</a>
            )}
          </div>
        );
      },
    },
    {
      field: "feedback",
      headerName: "Feedback",
      width: 110,
      renderCell: (params) => {
        return (
          <>
            {
              params.row?.feedback?.length > 0 ?
                <Feedback>
                  <button onClick={() => {
                    setfeedbackPopup(true)
                    setfeedbackData(params.row.feedback)
                  }}>View Feedback</button>
                </Feedback> : "No Feedback"
            }
          </>
        );
      },
    },
    {
      field: "project",
      headerName: "Project",
      width: 110,
      renderCell: (params) => {
        return (
          <div
            onClick={() => {
              setprojectpopup(true);
              setleadId(params.row.id);
              setprojectassign({ label: params.row.project });
            }}
            style={{
              color: colors.textBlue,
              cursor: 'pointer',
              padding: '4px 8px',
              border: '1px solid transparent',
              borderRadius: '4px',
              transition: 'background-color 0.2s',
            }}
          >
            {params.row.project}
          </div>
        );
      },
    },
    {
      field: "action",
      headerName: "Actions",
      width: 180,
      sortable: false,
      filterable: false,
      hideable: false,
      renderCell: (params) => {
        let whatsappLink = `https://wa.me/` + params.row.cellNo;
        let mailLink = `mailto:` + params.row.mail;
        return (
          <Action>
            <div
              onClick={() => {
                settask({
                  ...task, leadId: params.row.id,
                  clientId: params.row.clientId,
                  clientname: params.row.client,
                  phoneNo: params.row.cellNo,
                  projectName: params.row.project,
                  DProjectID: params.row.projectId,
                  leadStatus: params.row.leadstatus

                });
                setpopUp(true);
              }}
            >
              <img src="/assets/icons/plus.png" alt="plus" />
            </div>
            <a
              href={whatsappLink}
              onClick="location.href=this.href+'?key='+number;return false;"
              target="_blank"
              rel="noreferrer"
            >
              <WhatsAppIcon style={{ color: "green", cursor: "pointer" }} />
            </a>
            <ArrowCircleRightOutlinedIcon
              style={{ color: "#1194D1", cursor: "pointer" }}
              onClick={() => {
                setsingleAssignpopup(true);
                setleadId(params.row.id);
                setleadIdSm(params.row.leadId)
              }}
            />
            <ShareIcon
              style={{ color: "#EC2643", cursor: "pointer" }}
              onClick={() => {
                setleadSharePopUp(true);
                setleadId(params.row.id);
              }}
            />
            <RemoveRedEyeIcon
              style={{ color: `${colors.brown}`, cursor: "pointer" }}
              onClick={() => {
                setTaskPopUp(true);
                setleadId(params.row.id);
              }}
            />
          </Action>
        );
      },
    },
  ];
  let rows = [];
  leads?.leads?.forEach((lead) => {
    rows.push({
      client: lead?.clientID?.fullName,
      isNew: lead?.isNew,
      allocatedto: lead?.employeeID?.firstName,
      leadId: lead?.leadID,
      source: lead?.leadOpportunity?.source,
      project: lead?.leadOpportunity?.project?.projectName,
      projectId: lead?.leadOpportunity?.project?._id,
      classification: lead?.leadOpportunity?.classification,
      favourite: lead?.favourite,
      id: lead._id,
      leadstatus: lead?.leadStatus,
      clientId: lead.clientID?._id,
      cellNo: lead?.clientID?.cellNo[0]?.phoneNo,
      mail: lead?.clientID?.email,
      phone: lead?.clientID?.cellNo,
      feedback: lead?.feedback,
      badge: !lead?.taskExists
    });
  });

  const exportCSV = () => {
    const headers = [
      "Client",
      "Lead ID",
      "Source",
      "Phone",
      "Project"
    ];
    const leadsData = leads?.leads.map(lead => [
      lead.clientID?.fullName,
      lead?.leadID,
      lead?.leadOpportunity?.source,
      lead?.clientID?.cellNo[0]?.phoneNo,
      lead?.leadOpportunity?.project?.projectName
    ]);
    const csvContent = [
      headers.join(","),
      ...leadsData.map(lead => lead.join(","))
    ].join("\n");
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "Totalleads.csv");
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <Main>
      <LeadsSection>
        <div>
          Total Leads: <span>{leads?.totalLeads || 0}</span>
          <FormControlLabel
            sx={{ ml: 2 }}
            control={
              <Checkbox
                checked={checked}
                onChange={handleCheckboxChange}
              />
            }
            label={
              <span>
                Assigned New Leads{" "}
                {leads?.hasNewLead && (
                  <span
                    style={{
                      display: 'inline-block',
                      width: '10px',
                      height: '10px',
                      backgroundColor: `${colors.brown}`,
                      borderRadius: '50%',
                      marginLeft: '5px',
                      animation: 'blink 1s infinite',
                    }}
                  ></span>
                )}
              </span>
            }
          />
        </div>
        {role === "ceo" || role === "admin" || role === "account" || role === "bdm" ? (
          <StyledButton
            onClick={() => setleadsSharedPopUp(true)}
            disabled={multileadId.length === 0}
            isDisabled={multileadId.length === 0}
          >
            Assign Leads
          </StyledButton>
        ) : null}
        {role === "ceo" || role === "admin" || role === "account" ? (
          <Csvbutton onClick={exportCSV}>
            <FileDownloadIcon />
            Export
          </Csvbutton>
        ) : null}
      </LeadsSection>
      <Table>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => {
            setPageSize(newPageSize);
            setPage(1);
          }}
          onPageChange={(newPage) => setPage(newPage + 1)}
          rowsPerPageOptions={[20, 50, 100]}
          checkboxSelection
          onSelectionModelChange={(LeadIDs) => {
            setmultileadId(LeadIDs);
          }}
          experimentalFeatures={{ newEditingApi: true }}
          pagination
          paginationMode="server"
          loading={isFetching}
          rowCount={isFetching ? 0 : leads?.totalLeads || 0}
          sx={{
            border: "none",
          }}
        />
        {favouriteLoading ? <Loader /> : null}
      </Table>
      <PopUpContainer popUp={feedbackPopup}>
        <PopUp>
          <Cross onClick={() => setfeedbackPopup(!feedbackPopup)} style={{ height: "3%" }}>
            <img src="/assets/icons/cross.png" alt="cross" />
          </Cross>
          <PopUpSection style={{ height: "90%" }}>
            <FeedbackTable feedback={feedbackData} />
          </PopUpSection>
        </PopUp>
      </PopUpContainer>

      <PopUpContainer popUp={popUp}>
        <PopUp>
          <Cross
            // onClick={() => setpopUp(!popUp)}
            onClick={() => {
              settask({
                currentTaskType: "",
                currentSubTypeTask: "",
                completedDate: defaultDate,
                subType: "",
                reason: "",
                comment: "",
                nextTaskType: "",
                developername: "",
                deadline: "",
                DProjectID: "",
                cnic: "",
                totalAmount: 0,
                nationality: "",
                address: "",
                cnicfront: "",
                cnicback: "",
                personalimage: "",
                DTprojectBlockName: "",
                invenID: "",
                ammount: 0,
                paymentMode: "",
                bankName: "",
                transactionNo: 0,
                leadStatus: "",
                status: "",
                attachment: "",
                approvedPayment: "",
                leadId: task.leadId,
              });
              setpopUp(false);
            }}

          >
            <img src="/assets/icons/cross.png" alt="cross" />
          </Cross>
          <PopUpSection>
            <Section>
              <Task>
                <Heading>Add Task</Heading>
                <InputPart>
                  <h3>Current Task</h3>
                  <InputFeild>
                    <h3>Type:<b style={{ color: "red" }}>*</b></h3>
                    <div>
                      <select
                        name="currentTaskType"
                        id="currentTaskType"
                        value={task.currentTaskType}
                        onChange={handleType}
                      >
                        <option value="" disabled>
                          Select
                        </option>
                        <option value="Call">Call</option>
                        <option value="Meeting">Meeting</option>
                        <option value="Sales">Sales</option>
                        <option value="closed-lost">Closed (Lost)</option>
                      </select>
                    </div>
                  </InputFeild>
                  <InputFeild>
                    <h3>Sub-Type:<b style={{ color: "red" }}>*</b></h3>
                    <div>
                      <select
                        name="currentSubTypeTask"
                        id="currentSubTypeTask"
                        value={task.currentSubTypeTask}
                        onChange={handleSubtype}
                      >
                        <option value=""  >Select</option>
                        {subType?.map((ls) => (
                          <option value={ls} key={ls}>
                            {ls}
                          </option>
                        ))}
                      </select>
                    </div>
                  </InputFeild>
                  <InputFeild>
                    <h3>Completed Date:<b style={{ color: "red" }}>*</b></h3>
                    <div>
                      <input
                        type="datetime-local"
                        name="completedDate"
                        id="completedDate"
                        value={task.completedDate || defaultDate}
                        onChange={handleChange}
                        min={newMinDate}
                        max={defaultDate}
                      />
                    </div>
                  </InputFeild>
                  {closedLost ? (
                    <InputFeild>
                      <h3>Reason:<b style={{ color: "red" }}>*</b></h3>
                      <input
                        type="text"
                        name="reason"
                        id="reason"
                        placeholder="Type Here..."
                        value={task.reason}
                        onChange={handleChange}
                      />
                    </InputFeild>
                  ) : null}
                  {tokenDownPayment === false ? (
                    <>
                      <InputFeild>
                        <h3>Comment:<b style={{ color: "red" }}>*</b></h3>
                        <textarea
                          name="comment"
                          id="comment"
                          placeholder="type here..."
                          value={task.comment}
                          onChange={handleChange}
                        ></textarea>
                      </InputFeild>
                      {
                        task.currentTaskType === 'Meeting' || task.currentTaskType === "Sales" || task.currentSubTypeTask === "Whatsapp" ?
                          <InputFeild>
                            <h3>Attachment:<b style={{ color: "red" }}>*</b></h3>
                            <span>
                              <p>upload file here</p>
                              <label htmlFor="attachment">
                                <AttachFileIcon /> Upload
                              </label>
                              {task.attachment && (
                                <UploadedFile
                                  src={URL.createObjectURL(task.attachment)}
                                  alt="uploaded file"
                                />
                              )}
                            </span>
                            <input
                              type="file"
                              name="attachment"
                              id="attachment"
                              onChange={handleFileChange}
                            />
                          </InputFeild> : null}
                    </>
                  ) : null}
                  {tokenDownPayment ? (
                    <>
                      {/* <InputFeild>
                        <Headinginfo>Project Details</Headinginfo>
                      </InputFeild> */}

                      {task.leadStatus !== "TP" && task.leadStatus !== "DP" && task.leadStatus !== "PDP" && (
                        <>
                          <Heading>Project Details</Heading>
                          <InputFeild>
                            <h3>
                              Project:<b style={{ color: "red" }}>*</b>
                            </h3>
                            <div>
                              <select
                                name="DProjectID"
                                id="DProjectID"
                                disabled
                                value={selectedProject}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  setSelectedProject(value);
                                  settask((prevTask) => ({
                                    ...prevTask,
                                    DProjectID: value,
                                  }));
                                }}
                              >
                                <option value="">Select Project</option>
                                {project?.map((pro) => (
                                  <option key={pro._id} value={pro._id}>
                                    {pro.projectName}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </InputFeild>
                        </>
                      )}

                      {loading ? (
                        <TaskLoader>
                          <Puff
                            height="40"
                            width="40"
                            radius={1}
                            color={colors.brown}
                            ariaLabel="puff-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                          />
                        </TaskLoader>
                      ) : null}
                      {singleProject?.projectBlock?.length > 0 ? (
                        <InputFeild>
                          <h3>Project Block:</h3>
                          <div>
                            <select
                              name="DTprojectBlockName"
                              id="DTprojectBlockName"
                              onChange={handleChange}
                            >
                              <option value="">select</option>
                              {singleProject?.projectBlock?.map((pro) => (
                                <option value={pro.blockName}>
                                  {pro.blockName}
                                </option>
                              ))}
                            </select>
                          </div>
                        </InputFeild>
                      ) : null}
                      {task.leadStatus !== "TP" && task.leadStatus !== "DP" && task.leadStatus !== "PDP" && (
                        <InputFeild>
                          <h3>Unit: <b style={{ color: "red" }}>*</b></h3>
                          <div>
                            <input
                              type="text"
                              name="invenID"
                              id="invenID"
                              value={task.invenID}
                              onChange={handleChange}
                            />
                          </div>
                        </InputFeild>
                      )}
                      {task.leadStatus !== "TP" && task.leadStatus !== "DP" && task.leadStatus !== "PDP" && (
                        <InputFeild>
                          <h3>Developer Name:<b style={{ color: "red" }}>*</b></h3>
                          <div>
                            <input
                              type="text"
                              name="developername"
                              id="developername"
                              value={task.developername}
                              onChange={handleChange}
                            />
                          </div>
                        </InputFeild>
                      )}
                      {task.leadStatus !== "TP" && task.leadStatus !== "DP" && task.leadStatus !== "PDP" && (
                        <InputFeild>
                          <h3>Total Amount:<b style={{ color: "red" }}>*</b></h3>
                          <div>
                            <input
                              type="number"
                              name="totalAmount"
                              id="totalAmount"
                              value={task.totalAmount}
                              onChange={handleChange}
                            />
                          </div>
                        </InputFeild>
                      )}
                      {task.leadStatus !== "TP" && task.leadStatus !== "DP" && task.leadStatus !== "PDP" && (
                        <>
                          <Heading>Client Details</Heading>
                          <InputFeild>
                            <h3>
                              Client Name: <b style={{ color: "red" }}>*</b>
                            </h3>
                            <input
                              type="text"
                              name="clientname"
                              id="clientname"
                              disabled
                              value={task.clientname || ''}
                              onChange={handleChange}
                            />
                          </InputFeild>
                          <InputFeild>
                            <h3>
                              Phone: <b style={{ color: "red" }}>*</b>
                            </h3>
                            <input
                              type="number"
                              name="phone"
                              id="phone"
                              disabled
                              value={task.phoneNo}
                              onChange={handleChange}
                            />
                          </InputFeild>
                          <InputFeild>
                            <h3>
                              CNIC: <b style={{ color: "red" }}>*</b>
                            </h3>
                            <input
                              type="number"
                              name="cnic"
                              id="cnic"
                              value={task.cnic}
                              onChange={handleChange}
                            />
                          </InputFeild>
                          <InputFeild>
                            <h3>
                              Nationality: <b style={{ color: "red" }}>*</b>
                            </h3>
                            <input
                              type="text"
                              name="nationality"
                              id="nationality"
                              value={task.nationality}
                              onChange={handleChange}
                            />
                          </InputFeild>
                          <InputFeild>
                            <h3>
                              Address: <b style={{ color: "red" }}>*</b>
                            </h3>
                            <textarea
                              name="address"
                              id="address"
                              placeholder="type here..."
                              value={task.address}
                              onChange={handleChange}
                            ></textarea>
                          </InputFeild>
                        </>
                      )}

                      <Heading>Add Payment</Heading>
                      <InputFeild>
                        <h3>Payment Mode: <b style={{ color: "red" }}>*</b></h3>
                        <div>
                          <select
                            name="paymentMode"
                            id="paymentMode"
                            onChange={handleChange}
                            value={task.paymentMode}
                          >
                            <option value="">Select</option>
                            <option value="online">Online</option>
                            <option value="cheque">Cheque</option>
                            <option value="cash">Cash</option>
                            <option value="bank">Bank Transfer</option>
                          </select>
                        </div>
                      </InputFeild>
                      {task.paymentMode === "bank" && (
                        <BankInputFeild>
                          <h3>
                            Bank Name: <b style={{ color: "red" }}>*</b>
                          </h3>
                          <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={Banks}
                            value={task.bankName}
                            onChange={(event, newValue) =>
                              settask({ ...task, bankName: newValue })
                            }
                            sx={{
                              width: "100%",
                              border: `1px solid ${colors.inputBorderWhite}`,
                              borderRadius: "5px",
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                sx={{ backgroundColor: `${colors.inputWhite}` }}
                              />
                            )}
                          />
                        </BankInputFeild>
                      )}
                      <InputFeild>
                        <h3>Amount:<b style={{ color: "red" }}>*</b></h3>
                        <input
                          type="number"
                          name="ammount"
                          id="ammount"
                          value={task.ammount}
                          onChange={handleChange}
                        />
                      </InputFeild>
                      <InputFeild>
                        <h3>Transaction No:<b style={{ color: "red" }}>*</b></h3>
                        <input
                          type="number"
                          name="transactionNo"
                          id="transactionNo"
                          value={task.transactionNo}
                          onChange={handleChange}
                        />
                      </InputFeild>

                      {/* <InputFeild>
                        <h3>Deposite Date:<b style={{ color: "red" }}>*</b></h3>
                        <div>
                          <input
                            type="date"
                            name="depositeDate"
                            id="depositeDate"
                            value={task.depositeDate}
                            onChange={handleChange}
                          />
                        </div>
                      </InputFeild> */}
                      {task.currentSubTypeTask === "DP" && (
                        <InputFeild>
                          <h3>Personal Image:<b style={{ color: "red" }}>*</b></h3>
                          <span>
                            <p>upload file here</p>
                            <label htmlFor="personalimage">
                              <AttachFileIcon /> Upload
                            </label>
                            {task.personalimage && (
                              <UploadedFile
                                src={URL.createObjectURL(task.personalimage)}
                                alt="uploaded file"
                              />
                            )}
                          </span>
                          <input
                            type="file"
                            name="personalimage"
                            id="personalimage"
                            onChange={handleFileChange}
                          />
                        </InputFeild>
                      )}
                      {task.leadStatus !== "TP" && task.leadStatus !== "DP" && task.leadStatus !== "PDP" && (
                        <>
                          <InputFeild>
                            <h3>CNIC Front:<b style={{ color: "red" }}>*</b></h3>
                            <span>
                              <p>upload file here</p>
                              <label htmlFor="cnicfront">
                                <AttachFileIcon /> Upload
                              </label>
                              {task.cnicfront && (
                                <UploadedFile
                                  src={URL.createObjectURL(task.cnicfront)}
                                  alt="uploaded file"
                                />
                              )}
                            </span>
                            <input
                              type="file"
                              name="cnicfront"
                              id="cnicfront"
                              onChange={handleFileChange}
                            />
                          </InputFeild>
                          <InputFeild>
                            <h3>CNIC Back:<b style={{ color: "red" }}>*</b></h3>
                            <span>
                              <p>upload file here</p>
                              <label htmlFor="cnicback">
                                <AttachFileIcon /> Upload
                              </label>
                              {task.cnicback && (
                                <UploadedFile
                                  src={URL.createObjectURL(task.cnicback)}
                                  alt="uploaded file"
                                />
                              )}
                            </span>
                            <input
                              type="file"
                              name="cnicback"
                              id="cnicback"
                              onChange={handleFileChange}
                            />
                          </InputFeild>
                        </>
                      )}
                      <InputFeild>
                        <h3>Attach Payment Proof:<b style={{ color: "red" }}>*</b></h3>
                        <span>
                          <p>upload file here</p>
                          <label htmlFor="approvedPayment">
                            <AttachFileIcon /> Upload
                          </label>
                          {task.approvedPayment && (
                            <UploadedFile
                              src={URL.createObjectURL(task.approvedPayment)}
                              alt="uploaded file"
                            />
                          )}
                        </span>
                        <input
                          type="file"
                          name="approvedPayment"
                          id="approvedPayment"
                          onChange={handleFileChange}
                        />
                        <p>
                          Note: Invalid payment proof will result in
                          cancellation of payment and inventory will released
                          immediately.
                        </p>
                      </InputFeild>
                      <InputFeild>
                        <h3>Comment:<b style={{ color: "red" }}>*</b></h3>
                        <textarea
                          name="comment"
                          id="comment"
                          placeholder="type here..."
                          value={task.comment}
                          onChange={handleChange}
                        ></textarea>
                      </InputFeild>
                    </>
                  ) : null}
                </InputPart>
              </Task>
              <ButtonPart>
                <button
                  onClick={() => {
                    settask({
                      currentTaskType: "",
                      currentSubTypeTask: "",
                      completedDate: defaultDate,
                      subType: "",
                      reason: "",
                      comment: "",
                      nextTaskType: "",
                      developername: "",
                      deadline: "",
                      DProjectID: "",
                      cnic: "",
                      nationality: "",
                      address: "",
                      cnicfront: "",
                      cnicback: "",
                      personalimage: "",
                      DTprojectBlockName: "",
                      totalAmount: 0,
                      invenID: "",
                      ammount: 0,
                      paymentMode: "",
                      bankName: "",
                      transactionNo: 0,
                      leadStatus: "",
                      status: "",
                      attachment: "",
                      approvedPayment: "",
                      leadId: task.leadId,
                    });
                    setpopUp(false);
                  }}
                >
                  Cancel
                </button>
              </ButtonPart>
            </Section>
            <Section>
              <Task style={{ borderRight: "none" }}>
                <Heading>Add Task</Heading>
                <InputPart>
                  <h3>Next Task</h3>
                  <InputFeild>
                    <h3>Next Task:<b style={{ color: "red" }}>*</b></h3>
                    <div>
                      <select
                        name="nextTaskType"
                        id="nextTaskType"
                        value={task.nextTaskType || ""}
                        onChange={handleChange}
                      >
                        <option value="" disabled>Select</option>
                        <option value="doNothing">Do Nothing</option>
                        <option value="followUp">Follow Up</option>
                        <option value="contractedClient">Contracted Client</option>
                        <option value="closed-won">Close Won</option>
                        <option value="meetClient">Meet Client</option>
                        <option value="recievePartialDownPayment">Receive Partial Down Payment</option>
                        <option value="recieveCompleteDownPayment">Receive Complete Down Payment</option>
                        <option value="arrangeMeeting">Arrange Meeting</option>
                        <option value="receivetokenPayment">Receive Token Payment</option>
                      </select>
                    </div>
                  </InputFeild>

                  {doNothing === false ? (
                    <InputFeild>
                      <h3>Deadline:<b style={{ color: "red" }}>*</b></h3>
                      <div>
                        <input
                          type="datetime-local"
                          name="deadline"
                          id="deadline"
                          value={task.deadline}
                          onChange={handleChange}
                          min={new Date().toISOString().slice(0, 16)}
                        />
                      </div>
                    </InputFeild>
                  ) : null}
                </InputPart>
              </Task>
              <ButtonPart>
                <button
                  style={{
                    backgroundColor: `${colors.textBlue}`,
                    color: `${colors.white}`,
                    opacity: isLoading ? "0.5" : "1",
                  }}
                  onClick={handleSave}
                  disabled={isLoading}
                >
                  {isLoading ? <>Saving...</> : <>Save</>}
                </button>
              </ButtonPart>
            </Section>
          </PopUpSection>
        </PopUp>
      </PopUpContainer>
      <PopUpContainer popUp={taskPopUp}>
        <PopUp>
          <Cross
            onClick={() => setTaskPopUp(!taskPopUp)}
            style={{ height: "3%" }}
          >
            <img src="/assets/icons/cross.png" alt="cross" />
          </Cross>
          <PopUpSection style={{ height: "90%" }}>
            <TaskTable leadId={leadId} />
          </PopUpSection>
        </PopUp>
      </PopUpContainer>
      <PopUpContainer popUp={numberPopUp}>
        <NumberPopUp>
          <Cross
            onClick={() => setnumberPopUp(!numberPopUp)}
            style={{ height: "5%" }}
          >
            <img src="/assets/icons/cross.png" alt="cross" />
          </Cross>
          <NumberPopUpSection style={{ height: "90%", overflowY: "scroll", paddingTop: "1em" }}>

            {phoneData.map((phone) => (
              <div>
                <span>Phone No: </span> <a href={`tel:+${phone.phoneNo}`}>{phone.phoneNo}</a>

              </div>
            ))}
          </NumberPopUpSection>
        </NumberPopUp>
      </PopUpContainer>
      <PopUpContainer popUp={leadSharePopUp}>
        <NumberPopUp style={{ height: "50vh" }}>
          <Cross
            onClick={() => {
              setleadSharePopUp(!leadSharePopUp);
              setleadShareData({ ...leadShareData, sharingEmployee: null });
            }}
            style={{ height: "5%" }}
          >
            <img src="/assets/icons/cross.png" alt="cross" />
          </Cross>
          <div style={{ padding: '2em' }}>
            <StaffInputFeild>
              <h3>Staff:</h3>
              <Autocomplete
                id="size-small-standard-multi"
                size="small"
                // options={options}
                options={allStaffOptions}
                getOptionLabel={(option) => option.label}
                style={{
                  width: "100%",
                  borderRadius: "5px",
                  minHeight: "2.9em",
                  overflow: "hidden",
                  border: `1px solid ${colors.inputBorderWhite}`
                }}
                onChange={(e, value) => {
                  setleadShareData({ ...leadShareData, sharingEmployee: value })
                }}
                value={leadShareData.sharingEmployee}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    sx={{
                      backgroundColor: `${colors.white}`,
                      paddingTop: "1em",
                    }}
                  />
                )}
              />
            </StaffInputFeild>

            <InputFeild>
              <h3>Percentage:</h3>

              <input
                type="number"
                name="percentage"
                id="percentage"
                max={100}
                value={leadShareData.percentage}
                onChange={(e) => {
                  const newValue = Math.min(100, Math.max(0, e.target.value));
                  setleadShareData({ ...leadShareData, percentage: newValue });
                }}
              />
            </InputFeild>
            <ButtonPart>
              <button
                style={{
                  backgroundColor: `${colors.textBlue}`,
                  color: `${colors.white}`,
                  marginTop: "1em",
                  opacity: leadShareLoading ? "0.5" : "1",
                }}
                onClick={handleLeadShare}
                disabled={leadShareLoading}
              >
                {leadShareLoading ? <>sharing...</> : <>share</>}
              </button>
            </ButtonPart>
          </div>
        </NumberPopUp>
      </PopUpContainer>
      <PopUpContainer popUp={singleAssignpopup}>
        <NumberPopUp style={{ height: "50vh" }}>
          <Cross
            onClick={() => {
              setsingleAssignpopup(!singleAssignpopup);
              setSingleleadassign({ ...Singleleadassign, assignEmployee: null });
            }}
            style={{ height: "5%" }}
          >
            <img src="/assets/icons/cross.png" alt="cross" />
          </Cross>
          <div style={{ padding: '2em' }}>
            <StaffInputFeild>
              <h3>Staff:</h3>
              <Autocomplete
                id="size-small-standard-multi"
                size="small"
                options={
                  role === "ceo" || role === "admin" || role === "account"
                    ? allStaffOptions
                    : staffOptions
                }
                getOptionLabel={(option) => option.label}
                style={{
                  width: "100%",
                  borderRadius: "5px",
                  minHeight: "2.9em",
                  overflow: "hidden",
                  border: `1px solid ${colors.inputBorderWhite}`
                }}
                onChange={(e, value) => {
                  setSingleleadassign({ ...Singleleadassign, assignEmployee: value })
                }}
                value={Singleleadassign.assignEmployee}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    sx={{
                      backgroundColor: `${colors.white}`,
                      paddingTop: "1em",
                    }}
                  />
                )}
              />
            </StaffInputFeild>
            <ButtonPart>
              <button
                style={{
                  backgroundColor: Singleleadassign.assignEmployee && Singleleadassign.assignEmployee.id
                    ? `${colors.textBlue}`
                    : "gray",
                  color: `${colors.white}`,
                  marginTop: "1em",
                  opacity: singleleadAssignLoading ? "0.5" : "1",
                  cursor: "pointer",
                }}
                onClick={handleSingleLeadassign}
                disabled={singleleadAssignLoading || !Singleleadassign.assignEmployee || !Singleleadassign.assignEmployee.id}
              >
                {singleleadAssignLoading ? <>Assigning...</> : <>Assign</>}
              </button>
            </ButtonPart>
          </div>
        </NumberPopUp>
      </PopUpContainer>
      <PopUpContainer popUp={projectpopup}>
        <NumberPopUp style={{ height: "50vh" }}>
          <Cross
            onClick={() => {
              setprojectpopup(false);
              setprojectassign(null);
            }}
            style={{ height: "5%" }}
          >
            <img src="/assets/icons/cross.png" alt="cross" />
          </Cross>
          <div style={{ padding: '2em' }}>
            <StaffInputFeild>
              <h3>Project:</h3>
              <Autocomplete
                id="project-autocomplete"
                size="small"
                options={projectOptions}
                getOptionLabel={(option) => option.label}
                style={{
                  width: "100%",
                  borderRadius: "5px",
                  minHeight: "2.9em",
                  overflow: "hidden",
                  border: `1px solid ${colors.inputBorderWhite}`,
                }}
                onChange={(e, value) => {
                  setprojectassign(value);
                }}
                value={projectassign}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    sx={{
                      backgroundColor: colors.white,
                      paddingTop: "1em",
                    }}
                  />
                )}
              />
            </StaffInputFeild>
            <ButtonPart>
              <button
                style={{
                  backgroundColor: projectassign ? colors.textBlue : "gray",
                  color: colors.white,
                  marginTop: "1em",
                  opacity: projectassignLoading ? "0.5" : "1",
                  cursor: "pointer",
                }}
                onClick={handleChangeproj}
                disabled={projectassignLoading || !projectassign}
              >
                {projectassignLoading ? "Changing..." : "Change"}
              </button>
            </ButtonPart>
          </div>
        </NumberPopUp>
      </PopUpContainer>

      <PopUpContainer popUp={leadsSharedPopUp}>
        <NumberPopUp style={{ height: "50vh" }}>
          <Cross
            onClick={() => {
              setleadsSharedPopUp(!leadsSharedPopUp);
              setleadSharedData({ ...leadSharedData, assignEmployee: [] });
            }}
            style={{ height: "5%" }}
          >
            <img src="/assets/icons/cross.png" alt="cross" />
          </Cross>
          <div style={{ padding: "2em" }}>
            <StaffInputFeild>
              <h3>Staff:</h3>
              <Autocomplete
                id="size-small-standard-multi"
                multiple
                size="small"
                options={
                  role === "ceo" || role === "admin" || role === "account"
                    ? allStaffOptions
                    : staffOptions
                }
                getOptionLabel={(option) => option.label}
                style={{
                  width: "100%",
                  borderRadius: "5px",
                  minHeight: "2.9em",
                  overflow: "hidden",
                  border: `1px solid ${colors.inputBorderWhite}`,
                }}
                onChange={(e, value) => {
                  setleadSharedData({ ...leadSharedData, assignEmployee: value });
                }}
                value={leadSharedData.assignEmployee}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    sx={{
                      backgroundColor: `${colors.white}`,
                      paddingTop: "1em",
                    }}
                  />
                )}
              />
            </StaffInputFeild>
            <ButtonPart>
              <button
                style={{
                  backgroundColor: leadSharedData.assignEmployee.length > 0
                    ? `${colors.textBlue}`
                    : "gray",
                  color: `${colors.white}`,
                  marginTop: "1em",
                  opacity: leadsAssignLoading ? "0.5" : "1",
                  cursor: "pointer",
                }}
                onClick={handleMultiLeadAssign}
                disabled={leadsAssignLoading || leadSharedData.assignEmployee.length === 0}
              >
                {leadsAssignLoading ? <>Assigning...</> : <>Assign</>}
              </button>
            </ButtonPart>
          </div>
        </NumberPopUp>
      </PopUpContainer>

      <Toaster />
    </Main>
  );
};

const Main = styled.div`
  width: 90%;
  background-color: ${colors.white};
  margin: 3em 0em;
  height: 80vh;
  padding: 0em 1em;
  border-radius: 5px;
  position: relative;
`;
const LeadsSection = styled.p`
  font-size: clamp(1.5em, 1.5vh, 2em);
  padding: 0.5em 0em;
  height: 10%;
  display: flex;
  justify-content: space-between;
  span {
    font-weight: bold;
  }
`;
const Csvbutton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.textMed};
  padding: 14px;
  border: 1px solid ${colors.inputBorderWhite};
  border-radius: 5px;
  margin-left:5px;
  cursor: pointer;
  font-size: clamp(0.8em,0.9vw,1em);
`
const StyledButton = styled.button`
  display: flex;
  color: ${colors.white};
  background-color: ${(props) =>
    props.isDisabled ? 'gray' : colors.brown} !important;
  border: none !important;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  padding: 1em;
  cursor: pointer;
  font-size: clamp(0.6em, 0.7vw, 1em);
  opacity: ${(props) => (props.isDisabled ? 0.6 : 1)};
  margin-left: auto; 
`;


const Table = styled.div`
  height: 80%;
`;

const Client = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  div {
    display: flex;
    align-items: center;
  }
`;

const Id = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  div {
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: ${colors.textBlue};
  }
  p {
    color: ${colors.textMed};
  }
`;

const Action = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  div {
    width: 20px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    cursor: pointer;
  }
`;

const PopUpContainer = styled.div`
  background-color: #0000004d;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 11;
  display: ${(porps) => (porps.popUp ? "flex" : "none")};
  justify-content: center;
  align-items: center;
`;

const PopUp = styled.div`
  width: 90vw;
  height: 90vh;
  overflow: scroll;
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 5px;
`;
const NumberPopUp = styled.div`
  width: 30vw;
  height: 40vh;
  overflow: scroll;
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 5px;
`;

const Cross = styled.div`
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 2em;
  margin-top: 2em;
  img {
    height: 100%;
    object-fit: contain;
    cursor: pointer;
  }
`;

const PopUpSection = styled.div`
  margin-bottom: 3em;
  width: 100%;
  background-color: ${colors.white};
  display: flex;
  justify-content: space-around;
`;
const NumberPopUpSection = styled(PopUpSection)`
  flex-direction: column;
  margin: 1em;
  justify-content: center;
  line-height: 3em;
`;

const Section = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
`;

const Task = styled.div`
  border-bottom: 1px solid ${colors.inputFeild};
  border-right: 1px solid ${colors.inputFeild};
  padding: 1em 2em;
  min-height: 50vh;
  height: 100%;
`;

const Heading = styled.div`
  width: 100%;
  text-align: center;
  padding: 1em 0em;
  color: ${colors.textBlue};
  font-size: clamp(1.5em, 2vh, 3em);
`;

const InputPart = styled.div`
  h3 {
    font-size: clamp(1.5em, 2vh, 3em);
  }
`;
const Headinginfo = styled.h3`
    color: ${colors.black};
    font-size: clamp(1.5em, 2vh, 3em);
    margin: 1em 0em;
    // border-bottom: 2px solid ${colors.brown};
    // width: 15%;
`

const InputFeild = styled.div`
  width: 100%;
  h3 {
    color: ${colors.black};
    font-size: clamp(1em, 1vw, 2em);
    margin: 1em 0em;
  }
  p {
    color: ${colors.veryHot};
    margin-top: 0.5em;
  }
  div {
    position: relative;
    border: 1px solid ${colors.inputBorderWhite};
    border-radius: 6px;
    width: 100%;
    padding-right: 1em;
    background-color: ${colors.inputWhite};
    select,
    input[type="date"] {
      background-color: ${colors.inputWhite};
      width: 100%;
      padding: 1em 0em 1em 1em;
      outline: none;
      border: none;
      ::placeholder {
        color: ${colors.inputFeild};
      }
    }
  }
  input,
  textarea {
    border: 1px solid ${colors.inputBorderWhite};
    border-radius: 6px;
    background-color: ${colors.inputWhite};
    width: 100%;
    padding: 1em 0em 1em 1em;
    outline: none;
    ::placeholder {
      color: ${colors.inputFeild};
    }
  }
  span {
    background-color: ${colors.inputWhite};
    padding: 1em 0em 1em 1em;
    border-radius: 5px;
    border: 1px solid ${colors.inputBorderWhite};
    display: flex;
    position: relative;
    label {
      position: absolute;
      right: 3%;
      top: 50%;
      transform: translateY(-50%);
      background-color: ${colors.black};
      color: ${colors.white};
      padding: 0.5em 1em;
      border-radius: 5px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    p {
      font-size: clamp(1em, 1vh, 2em);
      color: ${colors.textMed};
    }
  }
  input[type="file"] {
    display: none;
  }
`;

const ButtonPart = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 15vh;
  button {
    padding: 1em 2em;
    border-radius: 5px;
    min-width: 8em;
    outline: none;
    border: none;
    background-color: ${colors.inputWhite};
    cursor: pointer;
  }
`;

const FixedContainer = styled.div`
  width: 100%;
  min-height: 3em;
  margin-top: 1em;
  background-color: ${colors.inputBorderWhite};
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 1em;
`;

const Price = styled.div`
  display: flex;
  p {
    margin-left: 2em;
  }
  margin-top: 1em;
`;

const TaskLoader = styled.div`
  margin-top: 1em;
`;

const BankInputFeild = styled.div`
  width: 100%;
  h3 {
    color: ${colors.black};
    font-size: clamp(1em, 1vw, 2em);
    margin: 1em 0em;
  }
`;

const UploadedFile = styled.img`
  border-radius: 50%;
  margin-left: 50%;
  width: 70px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

const StaffInputFeild = styled.div`
  /* width: 85%; */
  h3 {
    color: ${colors.black};
    font-size: clamp(1em, 1vw, 2em);
    margin: 1em 0em;
  }
`;


const Feedback = styled.div`
button{
  background-color: ${colors.brown};
  color: ${colors.white};
  border: none;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
}
`