import React, { useState, useMemo } from "react";
import Tree from "react-d3-tree";
import styled from "styled-components";
import { useLazyGetSingleEmployeeLeadsQuery } from "../../../features/leadsSlice";
import { colors } from "../../../Shared/colors";
import { toast } from 'react-hot-toast';

const TeamTreeGraph = ({ data, setTableData }) => {
  const userId = localStorage.getItem('id');
  const [id, setId] = useState('');

  const [trigger, { isLoading }] = useLazyGetSingleEmployeeLeadsQuery();

  const handleNodeClick = (nodeData) => {
    setId(nodeData?.data?.id);
  };

  const findNodeById = (data, targetId) => {
    if (!data) {
      return null;
    }
    if (data.id === targetId) {
      return data;
    }
    if (!data.children) {
      return null;
    }
    for (const child of data.children) {
      const foundNode = findNodeById(child, targetId);
      if (foundNode) {
        return foundNode;
      }
    }
    return null;
  };

  const hasIdOrChildWithId = (node, targetId) => {
    if (!node) {
      return false;
    }
    if (node.id === targetId) {
      return true;
    }
    if (!node.children) {
      return false;
    }
    for (const child of node.children) {
      if (hasIdOrChildWithId(child, targetId)) {
        return true;
      }
    }
    return false;
  };

  const heirarchObj = useMemo(() => {
    if (data) {
      return findNodeById(data, userId);
    }
    return null;
  }, [userId, data]);

  const handleView = () => {
    if (hasIdOrChildWithId(heirarchObj, id)) {
      trigger(id).unwrap()
        .then((res) => {
          setTableData(res);
          toast.success("Leads loaded successfully");
        })
        .catch((err) => {
          toast.error("Error while loading leads");
        });
    } else {
      toast.error("You can only see leads of your subordinates");
    }
  };


  return (
    <>
      <Main>
        <BtnPart>
          <Button onClick={handleView}>
            {isLoading ? <>Searching...</> : <>Search</>}
          </Button>
        </BtnPart>
        <TreePart>
          {data ? (
            <Tree
              data={data}
              translate={{ x: 700, y: 100 }}
              orientation="vertical"
              collapsible={true}
              nodeSvgShape={{ shape: "circle", shapeProps: { r: 4, fill: "red" } }}
              nodeSize={{ x: 270, y: 100 }}
              onNodeClick={handleNodeClick}
            />
          ) : (
            <p></p>
          )}
        </TreePart>
      </Main>
    </>
  );
};

export default TeamTreeGraph;

const Main = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  height: 100%;
`;

const BtnPart = styled.div`
  height: 20%;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const TreePart = styled.div`
  height: 80%;
  width: 100%;
`;

const Button = styled.button`
  min-width: 8em;
  padding: 1em;
  border: none;
  border-radius: 5px;
  background-color: ${colors.brown};
  color: ${colors.white};
  cursor: pointer;
`;
