import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { DataGrid } from '@mui/x-data-grid';
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { colors } from '../../../Shared/colors';
import { useGetTokenPaymentQuery } from '../../../features/accountSlice';
import { Button, Tooltip } from '@mui/material';
import { PaymentDetailatable } from './PaymentDetailatable';

export const TokenPaymentDataTable = () => {
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [selectedRow, setSelectedRow] = useState('');
  const [todoType, settodoType] = useState('all')
  const [type, setType] = useState('all')
  const [paymentPopUp, setPaymentPopUp] = useState(false);

  const { data: tokenPayment, isLoading, isFetching, refetch } = useGetTokenPaymentQuery(
    {
      page,
      limit: pageSize,
      paymentStatus: todoType,
      paymentType: type,
    },
    { refetchOnMountOrArgChange: true }
  );
  const columns = [
    {
      field: 'clientname',
      headerName: 'Client Name',
      width: 170,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <span>{params.value}</span>
        </Tooltip>
      ),
    },
    {
      field: "employee",
      headerName: "Employee Name",
      width: 170,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <span>{params.value}</span>
        </Tooltip>
      ),
    },
    {
      field: 'phone',
      headerName: 'Phone Number',
      width: 120,
    },
    {
      field: 'leadID',
      headerName: 'Lead ID',
      width: 80,
    },
    {
      field: 'project',
      headerName: 'Project',
      width: 150,
    },
    {
      field: 'unit',
      headerName: 'Unit',
      width: 70,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <span>{params.value}</span>
        </Tooltip>
      ),
    },
    {
      field: 'totalamount',
      headerName: 'Total Amount',
      width: 150,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <span>{params.value}</span>
        </Tooltip>
      ),
    },
    {
      field: 'Paymenttype',
      headerName: 'Payment Type',
      width: 100,
    },
    {
      field: 'statuspayment',
      headerName: 'Status',
      width: 150,
      renderCell: (params) => {
        const status = params.value;
        let color = 'gray';
        let buttonText = status;

        if (status === 'completed') {
          color = 'green';
          buttonText = 'completed';
        } else if (status === 'rejected') {
          color = 'red';
          buttonText = 'rejected';
        } else if (status === 'pending') {
          color = 'orange';
          buttonText = 'pending';
        }
        return (
          <Button
            style={{
              backgroundColor: color,
              color: 'white',
              padding: '5px 10px',
              fontWeight: 'bold',
            }}
            disabled
          >
            {buttonText}
          </Button>
        );
      },
    },
    {
      field: 'action',
      headerName: 'Actions',
      width: 70,
      sortable: false,
      filterable: false,
      hideable: false,
      renderCell: (params) => {
        const handleClick = () => {
          setPaymentPopUp(true);
          setSelectedRow(params.row);
        };

        return (
          <Action>
            <Tooltip title="View Details">
              <RemoveRedEyeIcon
                style={{ color: `${colors.brown}`, cursor: "pointer", marginLeft: "8px" }}
                onClick={handleClick}
              />
            </Tooltip>
          </Action>
        );
      }
    }
  ];

  const getButtonStyle = (status) => ({
    backgroundColor: todoType === status ? `${colors.brown}` : `${colors.white}`,
    color: todoType === status ? `${colors.white}` : `${colors.black}`,
    borderTopLeftRadius: "5px",
    borderTopRightRadius: "5px",
  });

  useEffect(() => {
    refetch();
  }, [todoType]);

  const rows = [];

  tokenPayment?.paymentInfo?.forEach(payment => {
    rows.push({
      id: payment?._id,
      clientname: payment.clientId?.fullName,
      employee: `${payment.employeeId?.firstName || ""} ${payment.employeeId?.lastName || ""}`.trim(),
      employeeId : payment?.employeeId?._id,
      phone: payment.clientId.cellNo[0]?.phoneNo,
      leadID: payment.leadId?.leadID,
      cnic: payment.clientId?.cnic,
      cnicfront: payment.clientId?.idCardFrontPicture,
      cnicback: payment.clientId?.idCardBackPicture,
      personalimg: payment.clientId?.personalPicture,
      nationality: payment.clientId?.country,
      address: payment.clientId?.address,
      unit: payment?.unitNumber,
      totalamount: payment?.totalAmount,
      Paymenttype: payment?.paymentType,
      project: payment.projectId?.projectName,
      developerName: payment.projectId?.developerName,
      statuspayment: payment.statusOfAllPayment,
      paymentDetails: payment?.paymentProof.map(proof => ({
        paymentStatus: proof?.paymentStatus,
        paymentPurpose: proof?.paymentPurpose,
        paymentMode: proof?.paymentMode,
        bank: proof?.bankName,
        amount: proof?.amount,
        paymentid: proof?._id,
        transactionId: proof?.transactionId,
        rejectreason: proof?.rejectionReason,
        reupload: proof?.reuploadByEmployee,
        paymentProofUrl: proof?.paymentProofUrl,
        depositeDate: new Date(proof.depositeDate).toLocaleDateString("en-US"),
      }))
    });
  });



  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
  };

  return (
    <Main>
      <TimeSection>
        <button
          style={getButtonStyle("pending")}
          onClick={() => settodoType("pending")}
        >
          Pending
        </button>
        <button
          style={getButtonStyle("completed")}
          onClick={() => settodoType("completed")}
        >
          Completed
        </button>
        <button
          style={getButtonStyle("rejected")}
          onClick={() => settodoType("rejected")}
        >
          Rejected
        </button>
        <button
          style={getButtonStyle("all")}
          onClick={() => settodoType("all")}
        >
          All
        </button>
      </TimeSection>
      <LeadsSection>
        <div>
          Total Payments: <span>{tokenPayment?.totalDocuments}</span>
        </div>
        <select
          name="Type"
          id="Type"
          onChange={(e) => setType(e.target.value)}
        >
          <option value="all">All</option>
          <option value="TP">TP</option>
          <option value="PDP">PDP</option>
          <option value="DP">DP</option>
        </select>
      </LeadsSection>
      <Table>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={pageSize}
          onPageSizeChange={handlePageSizeChange}
          rowsPerPageOptions={[5, 10, 20]}
          page={page - 1}
          onPageChange={handlePageChange}
          checkboxSelection
          disableSelectionOnClick
          // loading={isLoading}
          loading={isFetching}
          experimentalFeatures={{ newEditingApi: true }}
          sx={{ border: 'none' }}
        />
      </Table>
      <PopUpContainer popUp={paymentPopUp}>
        <PopUp>
          <Cross
            onClick={() => setPaymentPopUp(!paymentPopUp)}
            style={{ height: "3%" }}
          >
            <img src="/assets/icons/cross.png" alt="cross" />
          </Cross>
          <PopUpSection style={{ height: "90%" }}>
            <PaymentDetailatable payment={selectedRow} loading={isFetching} setPaymentPopUp={setPaymentPopUp} />
          </PopUpSection>
        </PopUp>
      </PopUpContainer>
    </Main>
  )
}

const Main = styled.div`
width: 90%;
background-color: ${colors.white};
margin: 1em 0em;
height: 80vh;
padding: 0em 1em;
border-radius: 5px;
`
const TimeSection = styled.div`
height: 10%;
border-bottom: 1px solid ${colors.inputBorderWhite};
display: flex;
align-items: flex-end;
button{
    padding: 1em;
    min-width: 10em;
    border: none;
    background-color: ${colors.white};
    cursor: pointer;
    transition: all 0.2s ease-out;
    :hover{
        background-color: ${colors.brown};
        color: ${colors.white};
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }
}
`

const LeadsSection = styled.p`
  font-size: clamp(1.5em, 1.5vh, 2em);
  padding: 0.5em 0em;
  height: 10%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  span {
    font-weight: bold;
  }
  select {
    outline: none;
    border: 1px solid ${colors.inputBorderWhite};
    border-radius: 5px;
    width :7%
  }

`
const Table = styled.div`
height: 80%;
`
const Action = styled.div`
display: flex;
justify-content: space-around;
width: 100%;
div{
  width: 20px;
  img{
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  cursor: pointer;
}
`
const PopUp = styled.div`
  width: 90vw;
  height: 90vh;
  overflow: scroll;
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 5px;
`;
const PopUpContainer = styled.div`
  background-color: #0000004d;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 11;
  display: ${(porps) => (porps.popUp ? "flex" : "none")};
  justify-content: center;
  align-items: center;
`;
const Cross = styled.div`
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 2em;
  margin-top: 2em;
  img {
    height: 100%;
    object-fit: contain;
    cursor: pointer;
  }
`;
const PopUpSection = styled.div`
  margin-bottom: 3em;
  width: 100%;
  background-color: ${colors.white};
  display: flex;
  justify-content: space-around;
`;