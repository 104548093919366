import React, { useMemo, useState, useEffect } from "react";
import styled from "styled-components";
import { colors } from "../../Shared/colors";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import LayersIcon from "@mui/icons-material/Layers";
import {
  useGetSingleHierarchyQuery,
  useGetStaffQuery,
} from "../../features/employeeSlice";
import { useGetProfileCardDataQuery } from "../../features/profileSlice"
import { useGetAllNotificationsQuery } from "../../features/notificationSlice";
import { Autocomplete, TextField } from "@mui/material";

import NotificationModel from "../notificationModel/NotificationModel";
import { decryptData } from "../../services/crypto";

export const DashboardFirst = ({ staff, setStaff, showModal }) => {
  const [data, setData] = useState({});
  let role = null;
  let storedStaffRole = localStorage.getItem("role");

  if (storedStaffRole) {
    try {
      role = decryptData(storedStaffRole);
    } catch (error) {
      console.error("Failed to decrypt data:", error);
    }
  }

  const { data: notificationsData, isLoading, error } = useGetAllNotificationsQuery();
  const [notificationMessage, setNotificationMessages] = useState('');
  const [showModalLocal, setShowModalLocal] = useState(showModal);


  useEffect(() => {
    if (notificationsData && Array.isArray(notificationsData) && notificationsData.length > 0) {
      const hasShownModal = localStorage.getItem("notificationShown");
      if (!hasShownModal || hasShownModal === "false") {
        setNotificationMessages([...notificationsData]);
        setShowModalLocal(true);
        localStorage.setItem("notificationShown", "true");
      }
    }
  }, [notificationsData]);


  let staffId = null;
  let storedStaffId = localStorage.getItem("id");

  if (storedStaffId) {
    try {
      staffId = decryptData(storedStaffId);
    } catch (error) {
      console.error("Failed to decrypt data:", error);
    }
  }
  if (!staffId) {
    console.error("Staff ID not found in localStorage");
  }

  const {
    data: hierarchyStaff,
    isLoading: isHierarchyLoading,
    error: hierarchyError,
  } = useGetSingleHierarchyQuery({ staffId });
  const { data: allStaff } = useGetStaffQuery();

  const staffOptions = useMemo(() => {
    let options = [];
    hierarchyStaff?.map((staff) =>
      options.push({
        id: staff?._id,
        label: staff?.firstName + " " + staff?.lastName,
      })
    );

    return options;
  }, [hierarchyStaff]);

  const allStaffOptions = useMemo(() => {
    let options = [];
    allStaff?.map((staff) =>
      options.push({
        id: staff?._id,
        label: staff?.firstName + " " + staff?.lastName,
      })
    );

    return options;
  }, [allStaff]);

  const selectedStaff =
    staffOptions.find((option) => option.id === staff?.id) || null;

  const { data: res, isFetching } = useGetProfileCardDataQuery({ employeeId: staff?.id || staffId })
  useEffect(() => {
    if (res) {
      setData(res)
    }
  }, [res])

  const handleCloseModal = () => {
    setShowModalLocal(false);
  };

  if (hierarchyError) {
    return <div>Error loading data</div>;
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error loading notifications</div>;
  }

  return (
    <Main>
      <Sub>
        <InputPart>
          <StaffInputField>
            <h3>Staff:</h3>
            <Autocomplete
              id="staff-autocomplete"
              size="small"
              options={
                role === "ceo" || role === "admin" || role === "account"
                  ? allStaffOptions
                  : staffOptions
              }
              getOptionLabel={(option) => option.label}
              style={{
                width: "100%",
                borderRadius: "5px",
                minHeight: "2.9em",
                marginLeft: "0.8em",
                overflow: "hidden",
                border: `1px solid ${colors.inputBorderWhite}`,
              }}
              onChange={(e, value) => {
                setStaff(value || null);
              }}
              clearOnEscape
              value={selectedStaff}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  sx={{
                    backgroundColor: `${colors.white}`,
                    paddingTop: "1em",
                  }}
                />
              )}
            />
          </StaffInputField>
        </InputPart>
        <CardPart>
          <CardSection>
            <CardIcon>
              <CalendarMonthIcon />
            </CardIcon>
            <h3>Meetings</h3>
            <h2>{isFetching ? "" : data?.meetingCount ?? "0"}</h2>
          </CardSection>
          <CardSection>
            <CardIcon>
              <LayersIcon />
            </CardIcon>
            <h3>DP</h3>
            <h2>{isFetching ? "" : data?.dpCounts ?? "0"}</h2>
          </CardSection>
          <CardSection>
            <CardIcon>
              <LayersIcon />
            </CardIcon>
            <h3>PDP</h3>
            <h2>{isFetching ? "" : data?.pdpCounts ?? "0"}</h2>
          </CardSection>
        </CardPart>
        {showModalLocal && (
          <>
            <Overlay onClick={handleCloseModal} />
            <NotificationModel
              notifications={notificationMessage}
              onClose={handleCloseModal}
            />
          </>
        )}
      </Sub>

    </Main>
  );
};
const Main = styled.div`
  background-color: ${colors.white};
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 3em;
  padding-top: 1rem;
  
`;
const Sub = styled.div`
  width: 92%;
  display: flex;
  justify-content: space-between;

  @media (min-width: 1024px) 
    width: 95%; 
    margin-left: 40px; 
  }
`;


const InputPart = styled.div`
  width: 45%;

`;

const CardPart = styled.div`
  width: 45%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1em;
  padding: 1em;
`;

const CardSection = styled.div`
  flex: 1 1 calc(30% - 1em); 
  background-color: ${colors.inputWhite};
  border-radius: 10px;
  border: 1px solid ${colors.inputBorderWhite};
  margin-right:1em;
  min-height: 12em;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
  }

  h3 {
    color: ${colors.brown};
    font-size: clamp(1em, 3vh, 1.5em);
    margin: 0;
  }

  h2 {
    font-size: clamp(1.5em, 4vh, 2.5em);
    color: ${colors.brownDark};
    margin: 0;
  }
`;

const CardIcon = styled.div`
  width: 3em;
  height: 3em;
  background-color: ${colors.lightBrown};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-bottom: 0.5em;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  .css-i4bv87-MuiSvgIcon-root {
    color: ${colors.brown};
    font-size: 1.5em;
  }
`;

const StaffInputField = styled.div`
margin-left:4em;
  h3 {
    color: ${colors.black};
    font-size: clamp(1em, 1vw, 2em);
    margin: 1em 0em 1em 1em;
  }
`;
const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); 
  backdrop-filter: blur(5px); 
  z-index: 999;
`;
