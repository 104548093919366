import React from 'react'
import styled from 'styled-components'
import { useMatch } from 'react-router-dom'
import { ClientsSidebar } from './ClientsSidebar';
import { LeadsSidebar } from './LeadsSidebar';
import { StaffSidebar } from './StaffSidebar';
import { AffiliateSidebar } from './AffiliateSidebar';
import { InventorySidebar } from './InventorySidebar';
import { AccountSidebar } from './AccountsSidebar';
import { TargetsSidebar } from './TargetsSidebar';
import { PoliciesSidebar } from './PoliciesSidebar';
import { ReportsSidebar } from './ReportsSidebar';
import { TrainingSidebar } from './TrainingSidebar';
import { DocumentationSidebar } from './DocumentationSidebar';
import { SocialSidebar } from './SocialSidebar';

export const Sidebar = () => {
  let dashboard = useMatch('/')

  let login = useMatch('/login')
  let Loginmatch = false;
  if (login || dashboard) {
    Loginmatch = true
  }

  let LeadsSidebarMatch = useMatch('/leads/*');

  let SocialSidebarMatch = useMatch('/social/*')

  let ClientsSidebarMatch = useMatch('/clients')

  let StaffSidebarMatch = useMatch('/staff/*')

  let AffiliateSidebarMatch = useMatch('/affiliate/*')

  let InventorySidebarMatch = useMatch('/inventory/*')

  let AccountSidebarMatch = useMatch('/accounts/*')

  let TargetsSidebarMatch = useMatch('/targets/*')

  let PoliciesSidebarMatch = useMatch('/policies/*')

  let ReportsSidebarMatch = useMatch('/reports/*')

  let TrainingSidebarMatch = useMatch('/training/*')

  let DocumentationSidebarMatch = useMatch('/documentation/*')

  return (
    <Main match={Loginmatch}>
      <Container>
        {
          LeadsSidebarMatch?.pattern?.end ?
            <LeadsSidebar />
            :
            SocialSidebarMatch?.pattern?.end ?
              <SocialSidebar />
              :
              ClientsSidebarMatch?.pattern?.end ?
                <ClientsSidebar />
                :
                StaffSidebarMatch?.pattern?.end ?
                  <StaffSidebar />
                  :
                  AffiliateSidebarMatch?.pattern?.end ?
                    <AffiliateSidebar />
                    :
                    InventorySidebarMatch?.pattern?.end ?
                      <InventorySidebar />
                      :
                      AccountSidebarMatch?.pattern?.end ?
                        <AccountSidebar />
                        :
                        TargetsSidebarMatch?.pattern?.end ?
                          <TargetsSidebar />
                          :
                          PoliciesSidebarMatch?.pattern?.end ?
                            <PoliciesSidebar />
                            :
                            ReportsSidebarMatch?.pattern?.end ?
                              <ReportsSidebar />
                              :
                              TrainingSidebarMatch?.pattern?.end ?
                                <TrainingSidebar />
                                :
                                DocumentationSidebarMatch?.pattern?.end ?
                                  <DocumentationSidebar />
                                  : null
        }
      </Container>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          opacity: 0.7,
        }}
      >

        <p
          style={{
            fontSize: '15px',
            color: '#4a4a4a',
            display: 'flex',
            alignItems: 'center',
            gap: 5,
          }}
        >
          Powered By{' '}
          <img
            src="/assets/icons/V3logo.png"
            alt="v3logo"
            style={{
              width: '20px',
              height: '20px',
            }}
          />
        </p>
      </div>
    </Main>
  )
}

const Main = styled.div`
  display: ${props => props.match ? 'none' : 'flex'};
  width: 20%;
  height: calc(100vh - 10vh);
  position: fixed;
  bottom: 0;
  flex-direction: column;
`;
const Container = styled.div`
 height: calc(100% - 30px); 
  padding: 1em 2em;
  overflow-y: auto;
`
