import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { ChangeCircle, LogoutRounded } from "@mui/icons-material";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { useGetProfileCardDataQuery } from "../../features/profileSlice"
import { Puff } from 'react-loader-spinner'
import { colors } from "../../Shared/colors";
import { decryptData } from "../../services/crypto";

const ProfileCard = ({
  user,
  handleLogout,
  handleReset,
}) => {
  const [drop, setDrop] = useState(false);
  const [data, setData] = useState({})
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const dropRef = useRef(null);
  
  let staffId = null;
  let employeeId = localStorage.getItem("id");

  if (employeeId) {
    try {
      staffId = decryptData(employeeId);
    } catch (error) {
      console.error("Failed to decrypt data:", error);
    }
  }
  if (!staffId) {
    console.error("Staff ID not found in localStorage");
  }

  const { data: res, isLoading, error } = useGetProfileCardDataQuery({ employeeId : staffId })
  useEffect(() => {
    if (res) {
      setData(res)
    }
  }, [res])
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropRef.current && !dropRef.current.contains(event.target)) {
        setDrop(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const handleLogoutClick = async () => {
    setIsLoggingOut(true); 
    try {
      await handleLogout();
    } catch (error) {
      console.error("Logout error:", error);
    } finally {
      setIsLoggingOut(false);
    }
  };
  return (
    <ProfileContainer>
      <Profile onClick={() => setDrop(!drop)}>
        <Img>
          <img src={user?.profilePic} alt="Profile" />
        </Img>
        <KeyboardArrowDownOutlinedIcon />
      </Profile>
      <Drop ref={dropRef} show={drop}>
        <Section>
          <ProfileHeading>Your Profile</ProfileHeading>
          <ProfilePhoto>
            <ProfileImage>
              <img src={user?.profilePic} alt="Profile" />
            </ProfileImage>
            <ProfileDetails>
              <UserName>{user?.firstName}</UserName>
              <UserEmail>{user?.email}</UserEmail>
              <UserRole>{user?.designation}</UserRole>
            </ProfileDetails>
          </ProfilePhoto>
        </Section>

        <Section>
          <ProfileHeading>Your Performance</ProfileHeading>
          <PerformanceGrid>
            <PerformanceItem>
              <p>DP Payment</p>
              <PerformanceValue>{data?.dpCounts}</PerformanceValue>
            </PerformanceItem>
            <PerformanceItem>
              <p>PDP Payment</p>
              <PerformanceValue>{data?.pdpCounts}</PerformanceValue>
            </PerformanceItem>
            <PerformanceItem>
              <p>Meetings</p>
              <PerformanceValue>{data?.meetingCount}</PerformanceValue>
            </PerformanceItem>
          </PerformanceGrid>
        </Section>
        <ActionButtons>
          <ActionButton onClick={handleLogoutClick} disabled={isLoggingOut}>
            {isLoggingOut ? (
              <Puff height="30" width="30" color={colors.brown} radius="10" visible={true} />
            ) : (
              <>
                <LogoutRounded sx={{ color: "red" }} /> <span>LogOut</span>
              </>
            )}
          </ActionButton>
          <ActionButton onClick={handleReset}>
            <ChangeCircle sx={{ color: "#249393" }} /> <span>Change Password</span>
          </ActionButton>
        </ActionButtons>
      </Drop>
    </ProfileContainer>
  );
};

export default ProfileCard;

const ProfileContainer = styled.div`
  position: relative;
`;

const Profile = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const Img = styled.div`
  img {
    width: 50px;
    height: 50px;
    object-fit: contain;
  }
`;

const Drop = styled.div`
  display: ${({ show }) => (show ? "block" : "none")};
  position: absolute;
  top: 50px;
  right: 0;
  background: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: 350px;
  padding: 20px;
  z-index: 1000;
`;

const Section = styled.div`
  margin-bottom: 20px;
`;

const ProfileHeading = styled.h4`
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 10px;
`;

const ProfilePhoto = styled.div`
  display: flex;
  align-items: center;
`;

const ProfileImage = styled.div`
  img {
    width: 60px;
    height: 60px;
    object-fit: contain;
    margin-right: 15px;
  }
`;

const ProfileDetails = styled.div`
  flex: 1;
`;

const UserName = styled.p`
  font-weight: bold;
  font-size: 1rem;
`;

const UserEmail = styled.p`
  font-size: 0.9rem;
  color: #666;
`;

const UserRole = styled.p`
  font-size: 0.9rem;
  color: #007bff;
  font-weight: bold;
`;

const PerformanceGrid = styled.div`
  display: flex;
  justify-content: space-between;
`;

const PerformanceItem = styled.div`
  text-align: center;

  p {
    margin: 0;
    font-size: 0.8rem;
    color: #666;
  }
`;

const PerformanceValue = styled.span`
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
`;

const ActionButtons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  border-top: 1px solid #f0f0f0;
  padding-top: 10px;
`;

const ActionButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 0.9rem;
  color: #333;

  span {
    margin-left: 5px;
  }

  &:hover {
    color: #007bff;
  }
`;
