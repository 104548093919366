import React, { useMemo, useState, useEffect } from "react";
import styled from "styled-components";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { colors } from "../../../Shared/colors";
import {
  useGetSingleHierarchyQuery,
  useGetStaffQuery,
  useLazyGetStaffKpiQuery,
} from "../../../features/employeeSlice";
import { useGetAllProjectsQuery } from "../../../features/projectSlice";
import { useGetAllClientsQuery, useLazyGetAllClientsQuery } from "../../../features/clientSlice";
import {
  useLazySearchLeadsFilterQuery,
  useLazySearchLeadsQuery,
} from "../../../features/leadsSlice";
import { SearchLeadsDataTable } from "./SearchLeadsDataTable";
import { toast, Toaster } from "react-hot-toast";
import { Box } from "@mui/material";
import { decryptData } from "../../../services/crypto";

export const SearchLeadsFirst = () => {
  let staffName = null;
  let storedStaffName = localStorage.getItem("staffName");

  if (storedStaffName) {
    try {
      staffName = decryptData(storedStaffName);
    } catch (error) {
      console.error("Failed to decrypt data:", error);
    }
  }

  let staffId = null;
  let storedStaffId = localStorage.getItem("id");

  if (storedStaffId) {
    try {
      staffId = decryptData(storedStaffId);
    } catch (error) {
      console.error("Failed to decrypt data:", error);
    }
  }

  let role = null;
    let storedStaffRole = localStorage.getItem("role");
  
    if (storedStaffRole) {
      try {
        role = decryptData(storedStaffRole);
      } catch (error) {
        console.error("Failed to decrypt data:", error);
      }
    }
  
  const [pageSize, setpageSize] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [isNewChecked, setIsNewChecked] = useState(false);
  const [kpiData, setkpiData] = useState({
    start: "",
    end: "",
    clientID: { id: "", label: "select" },
    callStatus: "",
    leadID: "",
    employeeID: { id: staffId, label: staffName },
    project: "",
    phoneNumber: "",
    isNew: false,
  });
  const [searchFilters, setSearchFilters] = useState({
    start: "",
    end: "",
    clientID: { id: "", label: "select" },
    callStatus: "",
    leadID: "",
    employeeID: { id: staffId, label: staffName },
    project: "",
    phoneNumber: "",
  });

  const { data: hierarchyStaff } = useGetSingleHierarchyQuery({ staffId });
  const { data: staff } = useGetStaffQuery();
  const { data: clients, isFetching: isClientFetcing, isLoading: isClientLoading } = useGetAllClientsQuery({ employeeId: kpiData?.employeeID?.id });

  const handleCheckboxChange = (event) => {
    setIsNewChecked(event.target.checked);
    handleSearch();
  };

  const [trigger, { data: leadData, isFetching, error }] =
    useLazySearchLeadsFilterQuery();

  const { data: project } = useGetAllProjectsQuery();

  const staffOptions = useMemo(() => {
    let options = [];
    hierarchyStaff?.map((staff) =>
      options.push({
        id: staff?._id,
        label: staff?.firstName + " " + staff?.lastName,
      })
    );

    return options;
  }, [hierarchyStaff]);

  const allStaffOptions = useMemo(() => {
    let options = [];
    staff?.map((staff) =>
      options.push({
        id: staff?._id,
        label: staff?.firstName + " " + staff?.lastName,
      })
    );

    return options;
  }, [staff]);

  const clientOptions = useMemo(() => {
    let options = [{ id: "", label: "select" }];
    clients?.LoginEmployeeClients.map((client) =>
      options.push({
        id: client?._id,
        label: client?.fullName,
      })
    );
    return options;
  }, [clients]);


  const handleChange = (e) => {
    const { name, value } = e.target;

    setkpiData({
      ...kpiData,
      [name]: value,
    });
  };

  const handleSearch = () => {
    const {
      project,
      classification,
      source,
      numTasks,
      leadID,
      from,
      start,
      end,
      callStatus,
      clientID,
      phoneNumber,
    } = kpiData;

    let employeeId =
      role === "ceo" || role === "admin" || role === "sbdm" || role === "bdm" || role === "account"
        ? kpiData.employeeID?.id || staffId
        : kpiData.employeeID?.id;

    const payload = {
      start,
      end,
      callStatus,
      projectID: project,
      employeeID: employeeId,
      clientID: clientID?.id,
      from,
      classification,
      phoneNumber,
      isNew: isNewChecked,
      source,
      numTasks,
      leadID,
      page: currentPage,
      limit: pageSize,
    };

    trigger(payload)
      .then((res) => {
        toast.success("Leads loaded successfully!");
      })
      .catch((err) => {
        console.error(err);
        const errorMessage =
          err?.data?.error?.message || "Failed to load leads";
        toast.error(errorMessage);
      })
      .finally(() => {
        setkpiData({
          ...kpiData,
          classification: "",
          source: "",
          numTasks: "",
          leadID: "",
          callStatus: "",
          start: "",
          end: "",
          project: "",
          from: "",
        });
      });

    setSearchFilters({
      projectID: project,
      employeeID: employeeId || staffId,
      classification,
      source,
      start: "",
      end: "",
      numTasks,
      leadID,
      callStatus,
      from,
    });
  };

  useEffect(() => {
    const {
      projectID,
      classification,
      source,
      numTasks,
      leadID,
      from,
      employeeID,
      start,
      end,
      callStatus,
      phoneNumber,
    } = searchFilters;

    let employeeId = kpiData.employeeID?.id || staffId;

    if (
      role === "ceo" ||
      role === "admin" ||
      role === "sbdm" ||
      role === "account" ||
      role === "bdm"
    ) {
      employeeId = kpiData.employeeID?.id || staffId;
    }

    trigger({
      projectID,
      employeeID: employeeId,
      from,
      classification,
      start,
      end,
      source,
      numTasks,
      isNew: isNewChecked,
      callStatus,
      phoneNumber,
      leadID,
      page: currentPage,
      pageSize,
    });
  }, [currentPage, pageSize, isNewChecked]);

  return (
    <>
      <Main>
        <Search>
          <SubHeading>Search Leads</SubHeading>
          <SearchSection1>
            <InputSection>
              <h3>Start:</h3>
              <div>
                <input
                  type="date"
                  name="start"
                  id="start"
                  value={kpiData.start}
                  onChange={handleChange}
                />
              </div>
            </InputSection>
            <InputSection>
              <h3>End:</h3>
              <div>
                <input
                  type="date"
                  name="end"
                  id="end"
                  value={kpiData.end}
                  onChange={handleChange}
                />
              </div>
            </InputSection>
            <InputSection>
              <h3>Phone Number:</h3>

              <input
                type="tel"
                name="phoneNumber"
                id="phoneNumber"
                value={kpiData.phoneNumber}
                onChange={handleChange}
              />
            </InputSection>
            <InputSection>
              <h3>Project:</h3>
              <div>
                <select
                  name="project"
                  id="project"
                  value={kpiData.project}
                  onChange={handleChange}
                >
                  <option value="">select</option>
                  {project?.map((proj) => (
                    <option key={proj._id} value={proj._id}>{proj.projectName}</option>
                  ))}
                </select>
              </div>
            </InputSection>
          </SearchSection1>
          <SearchSection2>
            <InputSection>
              <h3>Lead ID:</h3>
              <div>
                <input
                  type="text"
                  name="leadID"
                  id="lead"
                  value={kpiData.leadID}
                  onChange={handleChange}
                />
              </div>
            </InputSection>
            <InputSection>
              <h3>Call:</h3>
              <div>
                <select
                  name="callStatus"
                  id="callStatus"
                  value={kpiData.callStatus}
                  onChange={handleChange}
                >
                  <option value="">select</option>
                  <option value="Whatsapp">Whatsapp</option>
                  <option value="Call connected">Call connected</option>
                  <option value="Call Not Connected">Call Not Connected</option>
                </select>
              </div>
            </InputSection>
            {role === "ceo" ||
              role === "admin" ||
              role === "sbdm" ||
              role === "account" ||
              role === "bdm" ? (
              <StaffInputFeild>
                <h3>Staff:</h3>
                <Autocomplete
                  id="size-small-standard-multi"
                  size="small"
                  options={
                    role === "ceo" || role === "admin" || role === "account"
                      ? allStaffOptions
                      : staffOptions
                  }
                  getOptionLabel={(option) => option.label}
                  style={{
                    width: "90%",
                    borderRadius: "5px",
                    minHeight: "2.9em",
                    margin: "0 10px",
                    border: `1px solid ${colors.inputBorderWhite}`,
                  }}
                  onChange={(e, value) => {
                    setkpiData({ ...kpiData, employeeID: value, clientID: null });

                  }}
                  value={kpiData.staff}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      sx={{
                        backgroundColor: `${colors.white}`,
                        paddingTop: "1em",
                      }}
                    />
                  )}
                />
              </StaffInputFeild>
            ) : null}

            <StaffInputFeild>
              <h3>Client:</h3>
              <Autocomplete
                id="size-small-standard-multi"
                size="small"
                disabled={isClientLoading || isClientFetcing}
                options={clientOptions}
                getOptionLabel={(option) => option.label}
                style={{
                  width: "100%",
                  borderRadius: "5px",
                  minHeight: "2.9em",
                  overflow: "hidden",
                  border: `1px solid ${colors.inputBorderWhite}`,
                }}
                onChange={(e, value) => {
                  setkpiData({ ...kpiData, clientID: value });
                }}
                value={kpiData.clientID || null}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"

                    sx={{
                      backgroundColor: `${colors.white}`,
                      paddingTop: "1em",
                    }}
                  />
                )}
                renderOption={(props, option) => (
                  <Box component="li" {...props} key={option.id}>
                    {option.label}
                  </Box>
                )}
              />
            </StaffInputFeild>
            <Button onClick={handleSearch} disabled={isFetching}>
              {isFetching ? <>Searching...</> : <>Search</>}
            </Button>
          </SearchSection2>
        </Search>
      </Main>
      <Toaster />
      <SearchLeadsDataTable
        leads={leadData}
        pageSize={pageSize}
        setPageSize={setpageSize}
        setPage={setCurrentPage}
        checked={isNewChecked}
        handleCheckboxChange={handleCheckboxChange}
        isFetching={isFetching}
        currentPage={currentPage}
        totalPages={Math.ceil(leadData?.totalLeads / pageSize)}
      />
    </>
  );
};

const Main = styled.div`
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 30vh;
`;

const Search = styled.div`
  background-color: ${colors.inputWhite};
  width: 100%;
  box-shadow: -5px -8px 18px 8px #ffffff, 5px 8px 18px 3px #00000054;
  border-radius: 5px;
  min-height: 19em;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 1em;
`;

const SubHeading = styled.h4`
  height: 30%;
  width: 100%;
  color: ${colors.text};
  font-size: clamp(1.2em, 1.2vw, 1.5em);
  border-bottom: 1px solid ${colors.inputBorderWhite};
  padding-bottom: 1em;
`;

const SearchSection1 = styled.div`
  height: 30%;
  display: flex;
  justify-content: space-between;
`;

const SearchSection2 = styled.div`
  height: 30%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  // flex-wrap: wrap;
`;

const InputSection = styled.div`
  width: 40%;
  max-width: 25em;
  h3 {
    color: ${colors.black};
    font-size: clamp(1em, 1vw, 2em);
    margin: 1em 0em;
  }
  p {
    color: ${colors.veryHot};
    margin-top: 0.5em;
  }
  div {
    position: relative;
    border: 1px solid ${colors.inputBorderWhite};
    border-radius: 6px;
    width: 95%;
    padding-right: 1em;
    background-color: ${colors.white};

    select,
    input[type="date"],
    input[type="text"] {
      background-color: ${colors.white};
      width: 100%;
      padding: 1em 0em 1em 1em;
      outline: none;
      border: none;
      ::placeholder {
        color: ${colors.inputFeild};
      }
    }
  }
  input,
  textarea {
    border: 1px solid ${colors.inputBorderWhite};
    border-radius: 6px;
    background-color: ${colors.white};
    width: 90%;
    padding: 1em 0em 1em 1em;
    outline: none;
    ::placeholder {
      color: ${colors.inputFeild};
    }
  }
  span {
    background-color: ${colors.inputWhite};
    padding: 1em 0em 1em 1em;
    border-radius: 5px;
    border: 1px solid ${colors.inputBorderWhite};
    display: flex;
    position: relative;
    label {
      position: absolute;
      right: 3%;
      top: 50%;
      transform: translateY(-50%);
      background-color: ${colors.black};
      color: ${colors.white};
      padding: 0.5em 1em;
      border-radius: 5px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    p {
      font-size: clamp(1em, 1vh, 2em);
      color: ${colors.textMed};
    }
  }
  input[type="file"] {
    display: none;
  }
`;

const Button = styled.button`
  padding: 1em 2em;
  color: ${colors.white};
  background-color: ${colors.brown};
  border: none;
  border-radius: 5px;
  min-width: 10em;
  cursor: pointer;
`;

const StaffInputFeild = styled.div`
  width: 20em;
  h3 {
    color: ${colors.black};
    font-size: clamp(1em, 1vw, 2em);
    margin: 1em 0em;
  }
`;
