import { apiSlice } from "./api/apiSlice";


const profileSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProfileCardData: builder.query({
      query: ({ employeeId }) => {
        return {
          url: `dashboard/profile-data?employeeId=${employeeId}`,
        };
      },
      providesTags: ["Profile"],
    }),
  }),
});


export const { useGetProfileCardDataQuery } = profileSlice;