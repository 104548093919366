import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Tooltip from "@mui/material/Tooltip";
import { colors } from "../../../Shared/colors";
import styled from "styled-components";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import { usePaymentreuploadMutation, usePaymentverifyMutation } from "../../../features/accountSlice";
import toast, { Toaster } from "react-hot-toast";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { Autocomplete, Button } from '@mui/material';
import TextField from "@mui/material/TextField";
import { Banks } from "../../../Shared/Banks";
import { jsPDF } from 'jspdf'
import autoTable from 'jspdf-autotable';
import 'jspdf-autotable';
import { useDispatch } from "react-redux";
import { decryptData } from "../../../services/crypto";

export const PaymentDetailatable = ({ payment, loading, loadingfetch, setPaymentPopUp }) => {
    const dispatch = useDispatch();
    let role = null;
    let storedStaffRole = localStorage.getItem("role");
    let EmployeeId = null;
    let storedStaffId = localStorage.getItem("id");

    if (storedStaffId) {
        try {
            EmployeeId = decryptData(storedStaffId);
        } catch (error) {
            console.error("Failed to decrypt data:", error);
        }
    }

    if (storedStaffRole) {
        try {
            role = decryptData(storedStaffRole);
        } catch (error) {
            console.error("Failed to decrypt data:", error);
        }
    }

    const [open, setOpen] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const [reason, setReason] = useState("");
    const [paymentverify] = usePaymentverifyMutation();
    const [Paymentreupload, { isLoading }] = usePaymentreuploadMutation();
    const [isRejected, setIsRejected] = useState(false);
    const [pageSize, setPageSize] = useState(5);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [istaskPopupOpen, setIstaskPopupOpen] = useState(false);
    const [task, setTask] = useState({
        id: "",
        paymentype: '',
        paymentMode: '',
        bankName: '',
        ammount: '',
        transactionNo: '',
        approvedPayment: null,
    });

    const handleOpen = (id) => {
        setSelectedId(id);
        setOpen(true);
        setIsRejected(false);
    };

    const handleClose = () => {
        setOpen(false);
        setReason("");
        setIsRejected(false);
        setIstaskPopupOpen(false);
        setIsPopupOpen(false);
        setPaymentPopUp(false);
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        setTask((prev) => ({ ...prev, [name]: value }));
    };


    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setTask((prevState) => ({
            ...prevState,
            approvedPayment: file,
        }));
    };

    const handleOpenPopup = (id, purpose) => {
        const paymentTypeMap = {
            "Token Payment": "TP",
            "Partial Down Payment": "PDP",
            "Down Payment": "DP",
        };
        setTask({
            ...task,
            id: id,
            paymentype: paymentTypeMap[purpose] || "",
        });
        setIstaskPopupOpen(true);
    };

    const handleSave = async () => {
        const formData = new FormData();
        formData.append("paymentType", task.paymentype);
        formData.append("paymentMode", task.paymentMode);
        formData.append("bankName", task.bankName);
        formData.append("amount", task.ammount);
        formData.append("transactionId", task.transactionNo);

        if (task.approvedPayment) {
            formData.append("paymentproof", task.approvedPayment);
        }

        try {
            const response = await Paymentreupload({
                id: task.id,
                body: formData,
            }).unwrap();

            if (response?.success) {
                toast.success("Payment saved successfully!");
                setTask({
                    paymentype: '',
                    paymentMode: '',
                    bankName: '',
                    amount: '',
                    transactionNo: '',
                    approvedPayment: null,
                });
                setIstaskPopupOpen(false);
                handleClose();
            } else {
                toast.error("Failed to save payment!");
            }
        } catch (error) {
            console.error("Error saving payment:", error);
            toast.error("Failed to save payment!");
        }
    };



    const handleComplete = async () => {
        try {
            const response = await paymentverify({
                id: selectedId,
                body: { status: "completed" },
            }).unwrap();

            if (response.success) {
                toast.success("Payment completed successfully!");
                handleClose();
            } else {
                toast.error(response.message || "Unexpected error occurred");
            }
        } catch (error) {
            console.error("Error completing payment:", error);
            toast.error("Error completing payment!");
        } finally {
            handleClose();
        }
    };

    const handleReject = async () => {
        if (!reason.trim()) {
            toast.error("Please provide a rejection reason!");
            return;
        }

        try {
            const response = await paymentverify({
                id: selectedId,
                body: { status: "rejected", rejectionReason: reason },
            }).unwrap();

            if (response.success) {
                toast.success("Payment rejected successfully!");
                handleClose();
            } else {
                toast.error(response.message || "Payment rejection failed!");
            }
        } catch (error) {
            console.error("Error rejecting payment:", error);
            toast.error("Error rejecting payment!");
        } finally {
            handleClose();
        }
    };

    const openPopup = (row) => {
        setSelectedRow(row);
        setIsPopupOpen(true);
    };

    const closePopup = () => {
        setIsPopupOpen(false);
    };
    const exportAsPDF = async () => {
        const doc = new jsPDF();

        const fileName = `${selectedRow?.clientname || 'Client Name'}-${selectedRow?.leadID || 'Lead ID'}.pdf`;

        let yPos = 10;
        doc.text('Client Details', 14, yPos);
        yPos += 5;

        autoTable(doc, {
            head: [['Full Name', 'Phone Number', 'CNIC', 'Nationality', 'Address']],
            body: [
                [selectedRow.clientname, selectedRow.phone, selectedRow.cnic, selectedRow.nationality, selectedRow.address],
            ],
            startY: yPos,
            headStyles: {
                fillColor: '#249393',
                textColor: '#ffffff',
            }
        });

        yPos = doc.lastAutoTable.finalY + 10;
        doc.text('Project Details', 14, yPos);
        yPos += 5;

        autoTable(doc, {
            head: [['Project Name', 'Unit No', 'Developer Name', 'Amount', 'Status', 'Type']],
            body: [
                [selectedRow?.project, selectedRow?.unit, selectedRow?.developer, selectedRow.amount, selectedRow.paymentStatus, selectedRow.purpose],
            ],
            startY: yPos,
            headStyles: {
                fillColor: '#249393',
                textColor: '#ffffff',
            }
        });
        yPos = doc.lastAutoTable.finalY + 10;

        doc.text('Payment Details', 14, yPos);

        yPos += 10;

        const addImageToPDF = async (imageUrl, x, y, width, height) => {
            if (!imageUrl) return;

            try {
                const response = await fetch(imageUrl);
                const blob = await response.blob();
                const reader = new FileReader();

                return new Promise((resolve, reject) => {
                    reader.onloadend = () => {
                        const base64Data = reader.result;
                        if (base64Data) {
                            doc.addImage(base64Data, 'JPEG', x, y, width, height);
                            resolve();
                        } else {
                            reject(new Error('Failed to convert image to base64'));
                        }
                    };

                    reader.onerror = () => reject(new Error('Error reading file'));
                    reader.readAsDataURL(blob);
                });
            } catch (error) {
                console.error('Failed to add image to PDF:', error);
            }
        };

        const pageWidth = doc.internal.pageSize.width;

        const cnicImageWidth = 70;
        const cnicImageHeight = 50;
        const imageSpacing = 10;

        await addImageToPDF(
            selectedRow?.cnicfront,
            (pageWidth - cnicImageWidth * 2 - imageSpacing) / 2,
            yPos,
            cnicImageWidth,
            cnicImageHeight
        );

        await addImageToPDF(
            selectedRow?.cnicback,
            (pageWidth - cnicImageWidth * 2 - imageSpacing) / 2 + cnicImageWidth + imageSpacing,
            yPos,
            cnicImageWidth,
            cnicImageHeight
        );

        const paymentAttachmentWidth = 150;
        const paymentAttachmentHeight = 150;
        await addImageToPDF(
            selectedRow?.paymentProofUrl,
            (pageWidth - paymentAttachmentWidth) / 2,
            yPos + cnicImageHeight + imageSpacing,
            paymentAttachmentWidth,
            paymentAttachmentHeight
        );

        doc.save(fileName);
    };

    const columns = [
        {
            field: "purpose",
            headerName: "Payment Purpose",
            width: 150,
            renderCell: (params) => (
                <Tooltip title={params.value}>
                    <span>{params.value}</span>
                </Tooltip>
            ),
        },
        { field: "paymentMode", headerName: "Payment Mode", width: 120 },
        { field: "transactionId", headerName: "Transaction ID", width: 120 },
        { field: "amount", headerName: "Amount", width: 120 },
        {
            field: "bank",
            headerName: "Bank Name",
            width: 150,
            renderCell: (params) => (
                <Tooltip title={params.value}>
                    <span>{params.value}</span>
                </Tooltip>
            ),
        },
        {
            field: 'paymentStatus',
            headerName: 'Status',
            width: 150,
            renderCell: (params) => {
                const status = params.value;
                let color = 'gray';
                let buttonText = status;

                if (status === 'completed') {
                    color = 'green';
                    buttonText = 'completed';
                } else if (status === 'rejected') {
                    color = 'red';
                    buttonText = 'rejected';
                } else if (status === 'pending') {
                    color = 'orange';
                    buttonText = 'pending';
                }

                return (
                    <Button
                        style={{
                            backgroundColor: color,
                            color: 'white',
                            padding: '5px 10px',
                            fontWeight: 'bold',
                        }}
                        disabled
                    >
                        {buttonText}
                    </Button>
                );
            },
        },
        {
            field: "rejectreason",
            headerName: "Rejected Reason",
            width: 180,
            renderCell: (params) => (
                <Tooltip title={params.value || "No reason"}>
                    <span>{params.value || "No reason"}</span>
                </Tooltip>
            ),
        },
        {
            field: 'action',
            headerName: 'Actions',
            width: 95,
            sortable: false,
            filterable: false,
            hideable: false,
            renderCell: (params) => {
                const status = params.row?.paymentStatus;
                const empyId = params.row?.employeeId
                return (
                    <Action>
                        <Tooltip title="View Details">
                            <RemoveRedEyeIcon
                                style={{ color: `${colors.brown}`, cursor: "pointer" }}
                                onClick={() => openPopup(params.row)}
                            />
                        </Tooltip>
                        {role === 'ceo' || role === 'admin' || role === 'account' ?
                            <>
                                {status !== 'completed' && (
                                    <Tooltip title="Approve Payment">
                                        <CheckCircleIcon
                                            style={{ color: "green", cursor: "pointer" }}
                                            onClick={() => handleOpen(params.row.paymentid)}
                                        />
                                    </Tooltip>
                                )}
                            </>
                            : null}
                        {status === 'rejected' && empyId === EmployeeId && (
                            <Tooltip title="Reupload payment">
                                <ArrowCircleUpIcon
                                    style={{ color: "green", cursor: "pointer" }}
                                    onClick={() => handleOpenPopup(params.row.paymentid, params.row.purpose)}
                                />
                            </Tooltip>
                        )}
                    </Action>
                );
            }
        }

    ];
    const rows = [];
    const paymentDetails = Array.isArray(payment.paymentDetails) ? payment.paymentDetails : [];

    paymentDetails.forEach((paymentinfo) => {
        rows.push({
            id: paymentinfo?.paymentid,
            paymentid: payment?.id,
            clientname: payment?.clientname,
            employee: payment?.employee,
            employeeId: payment?.employeeId,
            phone: payment?.phone,
            leadID: payment?.leadID,
            cnic: payment?.cnic,
            cnicfront: payment?.cnicfront,
            cnicback: payment?.cnicback,
            nationality: payment?.nationality,
            address: payment?.address,
            developer: payment?.developerName,
            unit: payment?.unit,
            project: payment?.project,
            purpose: paymentinfo?.paymentPurpose,
            paymentStatus: paymentinfo?.paymentStatus,
            rejectreason: paymentinfo?.rejectreason,
            paymentMode: paymentinfo?.paymentMode,
            bank: paymentinfo?.bank,
            amount: paymentinfo?.amount,
            transactionId: paymentinfo?.transactionId,
            paymentProofUrl: paymentinfo?.paymentProofUrl,
            depositeDate: paymentinfo?.depositeDate,
        });
    });
    return (
        <>
            <DataGrid
                rows={rows}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5, 10, 20]}
                checkboxSelection
                disableSelectionOnClick
                sx={{ border: "none" }}
                loading={loading}
            />
            {open && (
                <ModalWrapper>
                    <Modal>
                        <ModalTitle>Update Payment Status</ModalTitle>
                        <ButtonContainer>
                            <ModalButton onClick={handleComplete} color="green">Complete</ModalButton>
                            <ModalButton onClick={() => setIsRejected(true)} color="red">Reject</ModalButton>
                        </ButtonContainer>

                        {isRejected && (
                            <TextField1
                                type="text"
                                placeholder="Rejection Reason"
                                value={reason}
                                onChange={(e) => setReason(e.target.value)}
                            />
                        )}

                        <ActionContainer>
                            <ModalButton onClick={handleClose} color="gray">Close</ModalButton>
                            {isRejected && (
                                <ModalButton onClick={handleReject} color="red">Add Reason</ModalButton>
                            )}
                        </ActionContainer>
                    </Modal>
                </ModalWrapper>
            )}
            {isPopupOpen && (
                <>
                    <Overlay onClick={closePopup} />
                    <Popup>
                        <CloseButton onClick={closePopup}>&times;</CloseButton>
                        <PopupContent>
                            <LeftColumn>
                                <FormSection>
                                    <h3>Client Details</h3>
                                    <FormGroup>
                                        <label>Full Name</label>
                                        <InfoText>{selectedRow?.clientname || "N/A"}</InfoText>
                                    </FormGroup>
                                    <FormGroup>
                                        <label>Phone Number</label>
                                        <InfoText>{selectedRow?.phone || "N/A"}</InfoText>
                                    </FormGroup>
                                    <FormGroup>
                                        <label>CNIC</label>
                                        <InfoText>{selectedRow?.cnic || "N/A"}</InfoText>
                                    </FormGroup>
                                    <FormGroup>
                                        <label>Nationality</label>
                                        <InfoText>{selectedRow?.nationality || "N/A"}</InfoText>
                                    </FormGroup>
                                    <FormGroup>
                                        <label>Address</label>
                                        <InfoText>{selectedRow?.address || "N/A"}</InfoText>
                                    </FormGroup>
                                </FormSection>
                                <FormSection>
                                    <h3>Project Details</h3>
                                    <FormGroup>
                                        <label>Project Name</label>
                                        <InfoText>{selectedRow?.project || "N/A"}</InfoText>
                                    </FormGroup>
                                    <FormGroup>
                                        <label>Unit No</label>
                                        <InfoText>{selectedRow?.unit || "N/A"}</InfoText>
                                    </FormGroup>
                                    <FormGroup>
                                        <label>Developer Name</label>
                                        <InfoText>{selectedRow?.developer || "N/A"}</InfoText>
                                    </FormGroup>
                                </FormSection>
                                <FormSection>
                                    <h3>Payment Proof</h3>
                                    <FormGroup>
                                        <label>Deposite Date</label>
                                        <InfoText> {selectedRow?.depositeDate
                                            ? new Intl.DateTimeFormat("en-US", {
                                                weekday: "short",
                                                day: "2-digit",
                                                month: "short",
                                                year: "numeric",
                                            }).format(new Date(selectedRow.depositeDate))
                                            : "N/A"}</InfoText>
                                    </FormGroup>
                                    <FormGroup>
                                        <label>Payment Mode</label>
                                        <InfoText>{selectedRow?.paymentMode || "N/A"}</InfoText>
                                    </FormGroup>
                                    <FormGroup>
                                        <label>Payment Type</label>
                                        <InfoText>{selectedRow?.purpose || "N/A"}</InfoText>
                                    </FormGroup>
                                    <FormGroup>
                                        <label>Payment Status</label>
                                        <InfoText>{selectedRow?.paymentStatus || "N/A"}</InfoText>
                                    </FormGroup>
                                    <FormGroup>
                                        <label>Amount</label>
                                        <InfoText>{selectedRow?.amount || "N/A"}</InfoText>
                                    </FormGroup>
                                </FormSection>
                            </LeftColumn>
                            <RightColumn>
                                <FormSection>
                                    <h3>Payment Details</h3>
                                    <FormGroup>
                                        <div style={{ display: "flex", gap: "20px", flexWrap: "wrap" }}>
                                            <div>
                                                <label>CNIC Front</label>
                                                {selectedRow?.cnicfront ? (
                                                    <ImageWrapper>
                                                        <img src={selectedRow.cnicfront} alt="CNIC Front" />
                                                    </ImageWrapper>
                                                ) : (
                                                    <InfoText>N/A</InfoText>
                                                )}
                                            </div>
                                            <div>
                                                <label>CNIC Back</label>
                                                {selectedRow?.cnicback ? (
                                                    <ImageWrapper>
                                                        <img src={selectedRow.cnicback} alt="CNIC Back" />
                                                    </ImageWrapper>
                                                ) : (
                                                    <InfoText>N/A</InfoText>
                                                )}
                                            </div>
                                        </div>
                                    </FormGroup>
                                    <FormGroup>
                                        <h3>Payment Attachment</h3>
                                        {selectedRow?.paymentProofUrl ? (
                                            <LargeImageWrapper>
                                                <img
                                                    src={selectedRow.paymentProofUrl}
                                                    alt="Payment Attachment"
                                                />
                                            </LargeImageWrapper>
                                        ) : (
                                            <InfoText>N/A</InfoText>
                                        )}
                                    </FormGroup>
                                </FormSection>
                            </RightColumn>
                        </PopupContent>
                        <Button1 onClick={exportAsPDF}>
                            <PictureAsPdfIcon />
                            Export
                        </Button1>
                    </Popup>
                </>
            )}
            {istaskPopupOpen && (
                <PopUpContainer>
                    <PopUp>
                        <PopUpSection1>
                            <Section1>
                                <CloseButton
                                    onClick={() => {
                                        setTask({
                                            paymentype: "",
                                            paymentMode: "",
                                            bankName: "",
                                            ammount: "",
                                            transactionNo: "",
                                            approvedPayment: "",
                                        });
                                        setIstaskPopupOpen(false);
                                    }}
                                >&times;
                                </CloseButton>
                                <Task>
                                    <Heading>Add Payment</Heading>
                                    <InputFeild>
                                        <h3>Payment Type: <b style={{ color: "red" }}>*</b></h3>
                                        <div>
                                            <select
                                                name="paymentype"
                                                id="paymentype"
                                                onChange={handleChange}
                                                value={task.paymentype}
                                            >
                                                <option value="">Select</option>
                                                <option value="TP">TP</option>
                                                <option value="PDP">PDP</option>
                                                <option value="DP">DP</option>
                                            </select>
                                        </div>
                                    </InputFeild>
                                    <InputFeild>
                                        <h3>Payment Mode: <b style={{ color: "red" }}>*</b></h3>
                                        <div>
                                            <select
                                                name="paymentMode"
                                                id="paymentMode"
                                                onChange={handleChange}
                                                value={task.paymentMode}
                                            >
                                                <option value="">Select</option>
                                                <option value="online">Online</option>
                                                <option value="cheque">Cheque</option>
                                                <option value="cash">Cash</option>
                                                <option value="bank">Bank Transfer</option>
                                            </select>
                                        </div>
                                    </InputFeild>
                                    {task.paymentMode === "bank" && (
                                        <BankInputFeild>
                                            <h3>
                                                Bank Name: <b style={{ color: "red" }}>*</b>
                                            </h3>
                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-demo"
                                                options={Banks}
                                                value={task.bankName}
                                                onChange={(event, newValue) =>
                                                    setTask({ ...task, bankName: newValue })
                                                }
                                                sx={{
                                                    width: "100%",
                                                    border: `1px solid ${colors.inputBorderWhite}`,
                                                    borderRadius: "5px",
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        sx={{ backgroundColor: `${colors.inputWhite}` }}
                                                    />
                                                )}
                                            />
                                        </BankInputFeild>
                                    )}
                                    <InputFeild>
                                        <h3>Amount: <b style={{ color: "red" }}>*</b></h3>
                                        <input
                                            type="number"
                                            name="ammount"
                                            id="ammount"
                                            value={task.ammount}
                                            onChange={handleChange}
                                        />
                                    </InputFeild>
                                    <InputFeild>
                                        <h3>Transaction No: <b style={{ color: "red" }}>*</b></h3>
                                        <input
                                            type="text"
                                            name="transactionNo"
                                            id="transactionNo"
                                            value={task.transactionNo}
                                            onChange={handleChange}
                                        />
                                    </InputFeild>
                                    <InputFeild>
                                        <h3>Attach Payment Proof:<b style={{ color: "red" }}>*</b></h3>
                                        <span>
                                            <p>upload file here</p>
                                            <label htmlFor="approvedPayment">
                                                <AttachFileIcon /> Upload
                                            </label>
                                            {task.approvedPayment && (
                                                <UploadedFile
                                                    src={URL.createObjectURL(task.approvedPayment)}
                                                    alt="uploaded file"
                                                />
                                            )}
                                        </span>
                                        <input
                                            type="file"
                                            name="approvedPayment"
                                            id="approvedPayment"
                                            onChange={handleFileChange}
                                        />
                                        <p>
                                            Note: Invalid payment proof will result in
                                            cancellation of payment and inventory will released
                                            immediately.
                                        </p>
                                    </InputFeild>
                                    <Buttondiv>
                                        <ButtonPart1>
                                            <button
                                                onClick={() => {
                                                    setTask({
                                                        paymentype: "",
                                                        paymentMode: "",
                                                        bankName: "",
                                                        ammount: "",
                                                        transactionNo: "",
                                                        approvedPayment: "",
                                                    });
                                                    setIstaskPopupOpen(false);
                                                }}
                                            >
                                                Cancel
                                            </button>
                                        </ButtonPart1>
                                        <ButtonPart1>
                                            <button
                                                style={{
                                                    backgroundColor: `${colors.textBlue}`,
                                                    color: `${colors.white}`,
                                                    opacity: isLoading ? "0.5" : "1",
                                                }}
                                                onClick={handleSave}
                                                disabled={isLoading}
                                            >
                                                {isLoading ? <>Saving...</> : <>Save</>}
                                            </button>
                                        </ButtonPart1>
                                    </Buttondiv>
                                </Task>
                            </Section1>
                        </PopUpSection1>
                    </PopUp>
                </PopUpContainer>
            )}
            <Toaster />
        </>
    );
};


const Action = styled.div`
display: flex;
justify-content: space-around;
width: 100%;
div{
  width: 20px;
  img{
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  cursor: pointer;
}
`
const ModalWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
`;

const Modal = styled.div`
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
`;

const ModalTitle = styled.h2`
    margin: 0;
    font-size: 18px;
    text-align: center;
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 10px;
    margin-top : 20px;
    
`;

const ModalButton = styled.button`
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: ${({ color }) => color};
    color: #fff;
    font-size: 14px;
    &:hover {
        opacity: 0.8;
    }
`;

const TextField1 = styled.textarea`
    width: 100%;
    padding: 8px;
    margin: 10px 0;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 14px;
`;

const ActionContainer = styled.div`
    display: flex;
    justify-content: right;
    margin-top: 20px;
    gap : 6px;
`;

const Popup = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 25px;
  border-radius: 8px;
  z-index: 1000;
  width: 90%;
  height: 90%;
  overflow-y: auto; 
  overflow-x: hidden;

  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 18px;
    background: none;
    border: none;
    cursor: pointer;
    color: #666;
  }

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${colors.brown};
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #999;
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

const FormSection = styled.div`
  h3 {
    margin-bottom: 15px;
    font-size: 28px;
    color: #333;
    border-bottom: 2px solid ${colors.brown};
    display: inline-block;
    padding-bottom: 5px;
  }

  .form-group {
    display: flex;
    margin-bottom: 15px;

    label {
      font-weight: bold;
      margin-bottom: 5px;
      font-size: 14px;
    }

`;

const Button1 = styled.button`
  display: flex;
  width: auto;
  padding: 10px 15px;
  background-color: ${colors.brown};
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;
  margin-left: auto; 
`;

const InfoText = styled.div`
  font-size: 18px;
  color: #333;
  padding: 8px 0;
`;
const FormGroup = styled.div`
  margin-bottom: 15px;

  label {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 5px;
    display: block;
  }
`;

const ImageWrapper = styled.div`
  margin: 10px 0;

  img {
    max-width: 250px;
    height: 250px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
`;

const LargeImageWrapper = styled.div`
  margin: 10px 0;

  img {
    width : 100%;
    height: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
`;
const PopupContent = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px;
  gap: 20px;
`;

const LeftColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const RightColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color : red ;
`;
const PopUpSection1 = styled.div`
  margin-bottom: 3em;
  z-index : 10;
  width: 100%;
  background-color: ${colors.white};
  display: flex;
  justify-content: space-around;
`;
const Section1 = styled.div`
  width: 50%;
  display: flex;
`;

const Task = styled.div`
  padding: 1em 2em;
  min-height: 50vh;
  height: 100%;
`;
const InputFeild = styled.div`
  width: 100%;
  h3 {
    color: ${colors.black};
    font-size: clamp(1em, 1vw, 2em);
    margin: 1em 0em;
  }
  p {
    color: ${colors.veryHot};
    margin-top: 0.5em;
  }
  div {
    position: relative;
    border: 1px solid ${colors.inputBorderWhite};
    border-radius: 6px;
    width: 100%;
    padding-right: 1em;
    background-color: ${colors.inputWhite};
    select,
    input[type="date"] {
      background-color: ${colors.inputWhite};
      width: 100%;
      padding: 1em 0em 1em 1em;
      outline: none;
      border: none;
      ::placeholder {
        color: ${colors.inputFeild};
      }
    }
  }
  input,
  textarea {
    border: 1px solid ${colors.inputBorderWhite};
    border-radius: 6px;
    background-color: ${colors.inputWhite};
    width: 100%;
    padding: 1em 0em 1em 1em;
    outline: none;
    ::placeholder {
      color: ${colors.inputFeild};
    }
  }
  span {
    background-color: ${colors.inputWhite};
    padding: 1em 0em 1em 1em;
    border-radius: 5px;
    border: 1px solid ${colors.inputBorderWhite};
    display: flex;
    position: relative;
    label {
      position: absolute;
      right: 3%;
      top: 50%;
      transform: translateY(-50%);
      background-color: ${colors.black};
      color: ${colors.white};
      padding: 0.5em 1em;
      border-radius: 5px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    p {
      font-size: clamp(1em, 1vh, 2em);
      color: ${colors.textMed};
    }
  }
  input[type="file"] {
    display: none;
  }
`;
const Heading = styled.div`
  width: 100%;
  text-align: center;
  padding: 1em 0em;
  color: ${colors.textBlue};
  font-size: clamp(1.5em, 2vh, 3em);
`;
const UploadedFile = styled.img`
  border-radius: 50%;
  margin-left: 50%;
  width: 70px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;
const ButtonPart1 = styled.div`
  display: flex;
  justify-content: center;
  button {
    padding: 1em 2em;
    border-radius: 5px;
    min-width: 8em;
    outline: none;
    border: none;
    background-color: ${colors.inputWhite};
    cursor: pointer;
    margin: 0 10px;  
  }
`;

const BankInputFeild = styled.div`
  width: 100%;
  h3 {
    color: ${colors.black};
    font-size: clamp(1em, 1vw, 2em);
    margin: 1em 0em;
  }
`;
const PopUp = styled.div`
  width: 70vw;
  height: 70vh;
  overflow: scroll;
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000; 
`;

const PopUpContainer = styled.div`
  background-color: #0000004d;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 11;
  justify-content: center;
  align-items: center;
`;
const Buttondiv = styled.div`
display : flex;
 align-items: center;
  justify-content: center;
 margin-top : 10px;
`