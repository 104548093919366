import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { colors } from "../../../Shared/colors";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { useGetDashboardReportQuery } from "../../../features/employeeSlice";
import { Loader } from "../../../Shared/Loader";

const monthNames = [
  "Jan", "Feb", "Mar", "Apr", "May", "Jun",
  "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
];

export const LeadsReport = ({ staff }) => {
  const { data: dashboard, isFetching } = useGetDashboardReportQuery({ staffId: staff?.id });

  const [leadReport, setLeadReport] = useState([]);

  
  useEffect(() => {
    if (!dashboard?.leadReport) {
      return;
    }

    const monthData = Array.from({ length: 12 }, (_, index) => ({
      name: monthNames[index],
      leads: 0,
    }));

    dashboard.leadReport.forEach((report) => {
      const monthIndex = report.month - 1;
      monthData[monthIndex].TLW = report.TLW;
    });

    setLeadReport(monthData);
  }, [dashboard]);

  return (
    <>
      {isFetching && <Loader />}
      <Main>
        {!isFetching && (
          <>
            <p>Leads Report</p>
            <Graph>
              <ResponsiveContainer width="100%" height="100%">
                <AreaChart
                  width={500}
                  height={400}
                  data={leadReport}
                  margin={{
                    top: 10,
                    right: 30,
                    left: 0,
                    bottom: 0,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Area
                    type="monotone"
                    dataKey="TLW"
                    stroke="#E3492D"
                    fill="#e3492d0d"
                  />
                </AreaChart>
              </ResponsiveContainer>
            </Graph>
          </>
        )}
      </Main>
    </>
  );
};

const Main = styled.div`
  width: 40%;
  min-width: 30em;
  height: 20em;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  background-color: ${colors.inputWhite};
  margin-top: 3em;
  padding: 1em;
  p {
    font-size: clamp(1em, 3vh, 4em);
    margin-bottom: 1em;
  }
`;

const Graph = styled.div`
  width: 100%;
  height: 80%;
`;
